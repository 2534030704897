const TEAM_TYPES = {
  RESET_STATE: "RESET_STATE",

  GET_LIST_BEGIN: "GET_TEAM_LIST_BEGIN",
  GET_LIST_SUCCESS: "GET_TEAM_LIST_SUCCESS",
  GET_LIST_FAILURE: "GET_TEAM_LIST_FAILURE",

  GET_DETAILS_BEGIN: "GET_TEAM_DETAILS_BEGIN",
  GET_DETAILS_SUCCESS: "GET_TEAM_DETAILS_SUCCESS",
  GET_DETAILS_FAILURE: "GET_TEAM_DETAILS_FAILURE",

  VERIFY_INVITE_BEGIN: "VERIFY_INVITE_BEGIN",
  VERIFY_INVITE_SUCCESS: "VERIFY_INVITE_SUCCESS",
  VERIFY_INVITE_FAILURE: "VERIFY_INVITE_FAILURE",

  ADD_MEMBER_BEGIN: "ADD_MEMBER_BEGIN",
  ADD_MEMBER_SUCCESS: "ADD_MEMBER_SUCCESS",
  ADD_MEMBER_FAILURE: "ADD_MEMBER_FAILURE",

  UPDATE_MEMBER_BEGIN: "UPDATE_MEMBER_BEGIN",
  UPDATE_MEMBER_SUCCESS: "UPDATE_MEMBER_SUCCESS",
  UPDATE_MEMBER_FAILURE: "UPDATE_MEMBER_FAILURE",

  SET_SUBMITTING: "SET_SUBMITTING",

  SET_FILTERS: "SET_FILTERS",
  RESET_FILTERS: "RESET_FILTERS",
};

export default TEAM_TYPES;
