import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';

import DefaultLayout from './DefaultLayout';
import BlankLayout from './BlankLayout';
import { logout, setIdleTimeout } from '../redux/actions/authActions';
import SessionIdleTimeout from '../components/SessionIdleTimeout';
import { performVerifyAdminSession, performVerifyAdminSessionOtp } from '../redux/actionCreators/authCreators';

function MasterLayout({
  children,
  isAuthenticated,
  sessionIdleTimeout,
  setIsIdle,
  verifyAdminSession,
  verifyAdminSessionOtp,
  logout,
  verifyPassword,
}) {
  const Layout = isAuthenticated ? DefaultLayout : BlankLayout;

  const handleVerifySession = useCallback(
    async (payload) => {
      await verifyAdminSession(payload);
    },
    [verifyAdminSession]
  );

  const handleVerifySessionOtp = useCallback(
    async (payload) => {
      await verifyAdminSessionOtp(payload);
    },
    [verifyAdminSessionOtp]
  );

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  const handleOnIdle = (event) => {
    if (isAuthenticated && !sessionIdleTimeout) setIsIdle(true);
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15, // 15 minutes
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return sessionIdleTimeout ? (
    <BlankLayout>
      <SessionIdleTimeout
        handleVerifySession={handleVerifySession}
        handleVerifySessionOtp={handleVerifySessionOtp}
        handleLogout={handleLogout}
        verifyPassword={verifyPassword}
      />
    </BlankLayout>
  ) : (
    <Layout>{children}</Layout>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  sessionIdleTimeout: state.auth.sessionIdleTimeout,
  verifyPassword: state.auth.verifyPassword,
});

const mapDispatchToProps = (dispatch) => ({
  setIsIdle: (payload) => dispatch(setIdleTimeout(payload)),
  verifyAdminSession: (payload) => dispatch(performVerifyAdminSession(payload)),
  verifyAdminSessionOtp: (payload) => dispatch(performVerifyAdminSessionOtp(payload)),
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MasterLayout);
