import { combineReducers } from 'redux';

import authReducer from './authReducer';
import alertReducer from './alertReducer';
import accountReducer from './accountReducer';
import teamReducer from './teamReducer';
import userReducer from './userReducer';
import goalReducer from './goalReducer';
import roleReducer from './roleReducer';
import settingReducer from './settingReducer';
import statReducer from './statReducer';
import notificationReducer from './notificationReducer';
import notificationSettingReducer from './notificationSettingReducer';

export default combineReducers({
  auth: authReducer,
  alert: alertReducer,
  account: accountReducer,
  team: teamReducer,
  role: roleReducer,
  user: userReducer,
  goal: goalReducer,
  setting: settingReducer,
  stat: statReducer,
  notification: notificationReducer,
  notificationSetting: notificationSettingReducer
});
