const ROLE_TYPES = {
  RESET_STATE: 'RESET_STATE',

  GET_LIST_BEGIN: 'GET_ROLE_LIST_BEGIN',
  GET_LIST_SUCCESS: 'GET_ROLE_LIST_SUCCESS',
  GET_LIST_FAILURE: 'GET_ROLE_LIST_FAILURE',

  GET_DETAILS_BEGIN: 'GET_ROLE_DETAILS_BEGIN',
  GET_DETAILS_SUCCESS: 'GET_ROLE_DETAILS_SUCCESS',
  GET_DETAILS_FAILURE: 'GET_ROLE_DETAILS_FAILURE',

  CREATE_ROLE_BEGIN: 'CREATE_ROLE_BEGIN',
  CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
  CREATE_ROLE_FAILURE: 'CREATE_ROLE_FAILURE',

  UPDATE_ROLE_BEGIN: 'UPDATE_ROLE_BEGIN',
  UPDATE_ROLE_SUCCESS: 'UPDATE_ROLE_SUCCESS',
  UPDATE_ROLE_FAILURE: 'UPDATE_ROLE_FAILURE',

  DELETE_ROLE_BEGIN: 'DELETE_ROLE_BEGIN',
  DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
  DELETE_ROLE_FAILURE: 'DELETE_ROLE_FAILURE',

  SET_FILTERS: 'SET_FILTERS',
  RESET_FILTERS: 'RESET_FILTERS',
};

export default ROLE_TYPES;
