export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const ENDPOINTS = {
  // Auth
  LOGIN: 'auth/login',
  RESEND_LOGIN_OTP: 'auth/resend-login-otp',
  VERIFY_LOGIN_OTP: 'auth/verify-login-otp',
  VERIFY_ADMIN_SESSION: 'auth/verify-admin-session',
  VERIFY_ADMIN_SESSION_OTP: 'auth/verify-session-otp',
  REFRESH_TOKEN: 'auth/refresh-token',
  FORGOT_PASSWORD: 'auth/forgot-password',
  RESET_PASSWORD: 'auth/reset-password',
  GET_PROFILE: 'account',

  // ACCOUNT
  UPDATE_PROFILE: 'account',
  UPDATE_PASSWORD: 'account/change-password',

  // TEAM
  GET_TEAM_LIST: 'staff',
  GET_MEMBER_DETAILS: (id) => `staff/${id}`,
  CREATE_TEAM_MEMBER: 'staff',
  UPDATE_TEAM_MEMBER: (id) => `staff/${id}`,
  ACTIVATE_ACCOUNT: 'staff/activate-account',
  RESEND_MEMBER_INVITE: 'staff/invite/resend',
  DELETE_MEMBER_INVITE: 'staff/invite',
  VERIFY_MEMBER_INVITE: 'staff/invite/verify',
  RESET_MEMBER_PASSWORD: (id) => `staff/${id}/reset-password`,

  // ROLES
  GET_ROLE_LIST: 'roles',
  GET_ROLE_DETAILS: (id) => `roles/${id}`,
  CREATE_ROLE: 'roles',
  UPDATE_ROLE: (id) => `roles/${id}`,
  DELETE_ROLE: (id) => `roles/${id}`,

  // USERS
  GET_USER_LIST: 'users',
  GET_USER_DETAILS: (id) => `users/${id}`,
  GET_USER_TIMELINE: (id) => `goals/get-user-timeline/${id}`,
  UPDATE_USER: (id) => `users/${id}`,
  DELETE_USER: (id) => `users/${id}`,

  // GOALS
  GET_GOAL_LIST: 'goals',
  GET_GOAL_DETAILS: (id) => `goals/get-user-goal-timeline/${id}`,
  UPDATE_GOAL: (id) => `goals/${id}`,
  CREATE_USER_REPORT: 'users/reports',
  CREATE_USER_GOAL_REPORT: (id) => `users/reports/goals/${id}`,
  GET_GENERATED_REPORTS_LIST: (id) => `users/reports/${id}`,
  GET_REPORTS_SIGNED_URL: (id) => `users/reports/get-signed-url/${id}`,

  // SETTINGS
  GET_COUNTRY_LIST: 'settings/countries',
  GET_BUCKET_LIST: `settings/all-countries`,
  GET_STATE_LIST: 'settings/states',
  GET_CITY_LIST: 'settings/cities',
  GET_REGION_LIST: 'settings/regions',
  GET_TAG_LIST: 'settings/tags',
  GET_PLAN_IOS_LIST: 'settings/subscription-plan?filter_platform=IOS',
  GET_PLAN_ANDROID_LIST: 'settings/subscription-plan?filter_platform=Android',
  GET_AVAIL_COUNTRY_LIST: 'settings/all-countries',
  GET_TRIAL: 'settings/settings',

  ADD_COUNTRY: 'settings/countries',
  ADD_STATE: 'settings/states',
  ADD_CITY: 'settings/cities',
  ADD_REGION: 'settings/regions',
  ADD_TAG: 'settings/tags',
  ADD_PLAN_IOS: 'settings/plan-ios',
  ADD_PLAN_ANDROID: 'settings/plan-android',
  ADD_AVAIL_COUNTRY: 'settings/all-countries',
  ADD_THRESHOLD: 'settings/threshold',

  UPDATE_COUNTRY: (id) => `settings/countries/${id}`,
  UPDATE_STATE: (id) => `settings/states/${id}`,
  UPDATE_CITY: (id) => `settings/cities/${id}`,
  UPDATE_REGION: (id) => `settings/regions/${id}`,
  UPDATE_TAG: (id) => `settings/tags/${id}`,
  UPDATE_PLAN_IOS: (id) => `settings/subscription-plan/${id}`,
  UPDATE_PLAN_ANDROID: (id) => `settings/subscription-plan/${id}`,
  UPDATE_AVAIL_COUNTRY: (id) => `settings/all-countries/${id}`,
  UPDATE_THRESHOLD: (id) => `settings/threshold/${id}`,
  UPDATE_BUCKET_LIST: (id) => `settings/all-countries/${id}`,
  UPDATE_TRIAL: (id) => `settings/settings/${id}`,

  DELETE_COUNTRY: (id) => `settings/countries/${id}`,
  DELETE_STATE: (id) => `settings/states/${id}`,
  DELETE_CITY: (id) => `settings/cities/${id}`,
  DELETE_REGION: (id) => `settings/regions/${id}`,
  DELETE_TAG: (id) => `settings/tags/${id}`,
  DELETE_PLAN_IOS: (id) => `settings/plan-ios/${id}`,
  DELETE_PLAN_ANDROID: (id) => `settings/plan-android/${id}`,
  DELETE_AVAIL_COUNTRY: (id) => `settings/all-countries/${id}`,
  DELETE_THRESHOLD: (id) => `settings/threshold/${id}`,

  IMPORT_COUNTRY_LIST: 'settings/countries/import',
  IMPORT_STATE_LIST: 'settings/states/import',
  IMPORT_CITY_LIST: 'settings/cities/import',
  IMPORT_FORMAT_LIST: 'settings/all-countries/default-format/import',

  IMPORT_COUNTRY_THRESHOLD_LIST: 'settings/countries/threshold/import',
  IMPORT_STATE_THRESHOLD_LIST: 'settings/states/threshold/import',
  IMPORT_CITY_THRESHOLD_LIST: 'settings/cities/threshold/import',

  EXPORT_COUNTRY_LIST: 'settings/countries/export',
  EXPORT_STATE_LIST: 'settings/states/export',
  EXPORT_CITY_LIST: 'settings/cities/export',
  EXPORT_FORMAT_LIST: 'settings/all-countries/sample/default-format',

  EXPORT_COUNTRY_THRESHOLD_LIST: 'settings/countries/threshold/sample',
  EXPORT_STATE_THRESHOLD_LIST: 'settings/states/threshold/sample',
  EXPORT_CITY_THRESHOLD_LIST: 'settings/cities/threshold/sample',

  // STATS
  GET_STAT_GOAL_TYPES: 'dashboard/goal-types-graph',
  GET_STAT_GOAL_CATEGORIES: 'dashboard/user-goals-category-graph',
  GET_STAT_GOAL_STATUS: 'dashboard/goal-status-graph',
  GET_STAT_USER_ACTIVITY: 'dashboard/user-activity-graph',
  GET_STAT_USER_BIOMETRIC: 'dashboard/user-biometric',
  GET_STAT_USER_PROFESSION: 'dashboard/user-profession-graph',
  GET_STAT_USER_NOTIFICATION: 'dashboard/user-notification-graph',
  GET_STAT_USER_ANALYSIS: 'dashboard/user-analysis-graph',
  GET_STAT_USER_TRAVEL: 'dashboard/user-travel-freq-graph',
  GET_STAT_USER_GROWTH_COUNTRY: 'dashboard/user-growth-country',
  GET_STAT_USER_LOCATIONS: 'dashboard/user-locations-graph',
  GET_GOALS_BY_COUNTRY: 'dashboard/country-goal',
  GET_GOALS_BY_STATE: 'dashboard/state-goal',
  GET_GOALS_BY_CITY: 'dashboard/city-goal',
  GET_ACTIVE_USER_FREQUENCY: 'dashboard/active-user-frequency',
  GET_USER_GROWTH_BY_OS: 'dashboard/user-os',
  GET_USER_PRESENCE_BY_OS: 'dashboard/user-presence-os',
  GET_USER_GROWTH_BY_CONVERSION: 'dashboard/user-growth-conversion',
  GET_CONVERTED_USER_BY_GOAL_COUNT: 'dashboard/user-goal-count-conversion',
  GET_CONVERTED_USER_BY_GOAL_TYPE: 'dashboard/user-goal-type-conversion',
  GET_CONVERTED_USER_BY_OS: 'dashboard/user-os-conversion',

  // NOTIFICATIONS
  GET_NOTIFICATION_LIST: 'notifications',
  GET_USERS_LIST: 'users/get-users',
  GET_NOTIFICATION_RECEIPIENTS: (id) => `notifications/${id}`,
  CREATE_NOTIFICATION: 'notifications',
  CREATE_NOTIFICATION_CAMPAIGN: 'notifications/notification-campaign',
  DAILY_REMINDER_NOTIFICATION_SETTING: 'notifications/daily-reminder',
  INACTIVITY_NOTIFICATION_SETTING: 'notifications/inactivity',
  LOGGED_OUT_NOTIFICATION_SETTING: 'notifications/logged-out',
  TRAVEL_BEHAVIOR_NOTIFICATION_SETTING: 'notifications/travel-behavior',
  NEW_RELEASE_NOTIFICATION_SETTING: 'notifications/new-release',
};
