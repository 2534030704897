import AUTH_TYPES from '../types/authTypes';

const initialState = {
  submitting: false,
  resendingOTP: false,
  isAuthenticated: false,
  loginOtpSent: false,
  loginOtpResentCount: 0,
  error: null,
  user: null,
  role: null,
  token: null,
  isLoginOtpVerified: false,
  sessionIdleTimeout: false,
  verifyPassword: false,
  profileData: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_TYPES.RESET_STATE:
      return {
        ...state,
        submitting: false,
        error: null,
      };

    case AUTH_TYPES.UPDATE_USER_DATA:
      return {
        ...state,
        user: action.payload,
      };

    case AUTH_TYPES.LOGIN_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
        token: null,
      };

    case AUTH_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        submitting: false,
        loginOtpSent: true,
      };

    case AUTH_TYPES.LOGIN_NO_2FA_SUCCESS:
      return {
        ...state,
        submitting: false,
        isAuthenticated: true,
        user: action.payload.user,
        role: action.payload.role,
        token: action.payload.token,
        profileData: action.payload.user,
      };

    case AUTH_TYPES.LOGIN_FAILURE:
      return {
        ...state,
        submitting: false,
        loginOtpSent: false,
      };

    case AUTH_TYPES.RESEND_LOGIN_OTP_BEGIN:
      return {
        ...state,
        resendingOTP: true,
        error: null,
        token: null,
      };

    case AUTH_TYPES.RESEND_LOGIN_OTP_SUCCESS:
      return {
        ...state,
        resendingOTP: false,
        loginOtpResentCount: state.loginOtpResentCount + 1,
      };

    case AUTH_TYPES.RESEND_LOGIN_OTP_FAILURE:
      return {
        ...state,
        resendingOTP: false,
      };

    case AUTH_TYPES.VERIFY_LOGIN_OTP_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
        token: null,
      };

    case AUTH_TYPES.VERIFY_LOGIN_OTP_SUCCESS:
      return {
        ...state,
        submitting: false,
        isAuthenticated: true,
        isLoginOtpVerified: true,
        user: action.payload.user,
        role: action.payload.role,
        token: action.payload.token,
        profileData: action.payload.user,
      };

    case AUTH_TYPES.VERIFY_LOGIN_OTP_FAILURE:
      return {
        ...state,
        submitting: false,
        isAuthenticated: false,
        isLoginOtpVerified: false,
      };

    case AUTH_TYPES.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
      };

    case AUTH_TYPES.CHANGE_PASSWORD_BEGIN:
      return {
        ...initialState,
        submitting: true,
      };

    case AUTH_TYPES.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        submitting: false,
      };

    case AUTH_TYPES.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        submitting: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case AUTH_TYPES.VERIFY_ADMIN_SESSION_BEGIN:
      return {
        ...state,
      };

    case AUTH_TYPES.VERIFY_ADMIN_SESSION_SUCCESS:
      return {
        ...state,
        verifyPassword: state.user.isTwoFactor,
        sessionIdleTimeout: state.user.isTwoFactor,
      };

    case AUTH_TYPES.VERIFY_ADMIN_SESSION_FAILURE:
      return {
        ...state,
        verifyPassword: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };
    case AUTH_TYPES.VERIFY_ADMIN_SESSION_OTP_BEGIN:
      return {
        ...state,
      };

    case AUTH_TYPES.VERIFY_ADMIN_SESSION_OTP_SUCCESS:
      return {
        ...state,
        sessionIdleTimeout: false,
      };

    case AUTH_TYPES.VERIFY_ADMIN_SESSION_OTP_FAILURE:
      return {
        ...state,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case AUTH_TYPES.SET_SUBMITTING:
      return {
        ...state,
        submitting: action.payload,
      };

    case AUTH_TYPES.SET_IDLE_TIMEOUT:
      return {
        ...state,
        sessionIdleTimeout: action.payload,
        verifyPassword: !action.payload,
      };

    case AUTH_TYPES.LOGOUT:
      return initialState;

    case AUTH_TYPES.GET_PROFILE_DETAILS_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case AUTH_TYPES.GET_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        profileData: action.payload,
        submitting: false,
        error: null,
      };

    case AUTH_TYPES.GET_PROFILE_DETAILS_FAILURE:
      return {
        ...state,
        submitting: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    default:
      return state;
  }
}
