import AUTH_TYPES from '../types/authTypes';

export const resetState = () => ({
  type: AUTH_TYPES.RESET_STATE,
});

export const updateUserData = (data) => ({
  type: AUTH_TYPES.UPDATE_USER_DATA,
  payload: data,
});

export const loginBegin = () => ({
  type: AUTH_TYPES.LOGIN_BEGIN,
});

export const loginSuccess = (data) => ({
  type: AUTH_TYPES.LOGIN_SUCCESS,
  payload: data,
});

export const loginNo2FASuccess = (data) => ({
  type: AUTH_TYPES.LOGIN_NO_2FA_SUCCESS,
  payload: data,
});

export const loginFailure = (error) => ({
  type: AUTH_TYPES.LOGIN_FAILURE,
  payload: { error },
});

export const resendLoginOTPBegin = () => ({
  type: AUTH_TYPES.RESEND_LOGIN_OTP_BEGIN,
});

export const resendLoginOTPSuccess = (data) => ({
  type: AUTH_TYPES.RESEND_LOGIN_OTP_SUCCESS,
  payload: data,
});

export const resendLoginOTPFailure = (error) => ({
  type: AUTH_TYPES.RESEND_LOGIN_OTP_FAILURE,
  payload: { error },
});

export const verifyLoginOTPBegin = () => ({
  type: AUTH_TYPES.VERIFY_LOGIN_OTP_BEGIN,
});

export const verifyLoginOTPSuccess = (data) => ({
  type: AUTH_TYPES.VERIFY_LOGIN_OTP_SUCCESS,
  payload: data,
});

export const verifyLoginOTPFailure = (error) => ({
  type: AUTH_TYPES.VERIFY_LOGIN_OTP_FAILURE,
  payload: { error },
});

export const refreshTokenSuccess = (data) => ({
  type: AUTH_TYPES.REFRESH_TOKEN_SUCCESS,
  payload: data,
});

export const changePasswordBegin = () => ({
  type: AUTH_TYPES.CHANGE_PASSWORD_BEGIN,
});

export const changePasswordSuccess = () => ({
  type: AUTH_TYPES.CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordFailure = (error) => ({
  type: AUTH_TYPES.CHANGE_PASSWORD_FAILURE,
  payload: { error },
});

export const verifySessionBegin = () => ({
  type: AUTH_TYPES.VERIFY_ADMIN_SESSION_BEGIN,
});

export const verifySessionSuccess = () => ({
  type: AUTH_TYPES.VERIFY_ADMIN_SESSION_SUCCESS,
});

export const verifySessionFailure = (error) => ({
  type: AUTH_TYPES.VERIFY_ADMIN_SESSION_FAILURE,
  payload: { error },
});

export const verifySessionOtpBegin = () => ({
  type: AUTH_TYPES.VERIFY_ADMIN_SESSION_OTP_BEGIN,
});

export const verifySessionOtpSuccess = () => ({
  type: AUTH_TYPES.VERIFY_ADMIN_SESSION_OTP_SUCCESS,
});

export const verifySessionOtpFailure = (error) => ({
  type: AUTH_TYPES.VERIFY_ADMIN_SESSION_OTP_FAILURE,
  payload: { error },
});

export const setSubmitting = (data) => ({
  type: AUTH_TYPES.SET_SUBMITTING,
  payload: data,
});

export const setIdleTimeout = (data) => ({
  type: AUTH_TYPES.SET_IDLE_TIMEOUT,
  payload: data,
});

export const logout = () => ({
  type: AUTH_TYPES.LOGOUT,
});

export const getProfileDetailsBegin = () => ({
  type: AUTH_TYPES.GET_PROFILE_DETAILS_BEGIN,
});

export const getProfileDetailsSuccess = (data) => ({
  type: AUTH_TYPES.GET_PROFILE_DETAILS_SUCCESS,
  payload: data,
});

export const getProfileDetailsFailure = (error) => ({
  type: AUTH_TYPES.GET_PROFILE_DETAILS_FAILURE,
  payload: { error },
});
