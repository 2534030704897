import USER_TYPES from '../types/userTypes';

const initialFilters = Object.seal([
  {
    name: 'status',
    type: 'select',
    placeHolder: 'Select Blocked Status',
    options: [
      {
        value: 'Active',
        label: 'Active',
      },
      {
        value: 'Inactive',
        label: 'Inactive',
      },
    ],
    value: null,
  },
  {
    name: 'isSubscribed',
    type: 'select',
    placeHolder: 'Subscribed',
    options: [
      {
        value: 'true',
        label: 'True',
      },
      {
        value: 'false',
        label: 'False',
      },
    ],
    value: null,
  },
  {
    name: 'subscriptionPlatform',
    type: 'select',
    placeHolder: 'Subscription Platform',
    options: [
      {
        value: 'Android',
        label: 'Android',
      },
      {
        value: 'Ios',
        label: 'Ios',
      },
    ],
    value: null,
  },
  {
    name: 'subscriptionPlanType',
    type: 'select',
    placeHolder: 'Subscription Plan Type',
    options: [
      {
        value: 'Yearly',
        label: 'Yearly',
      },
      {
        value: 'Monthly',
        label: 'Monthly',
      },
    ],
    value: null,
  },
  {
    name: 'createdAt',
    type: 'daterange',
    label: 'Date Added',
    value: null,
  },
]);

const initialState = {
  fetching: false,
  updating: false,
  error: null,
  list: [],
  details: null,
  goalsData: null,
  timelineData: [],
  filters: initialFilters,
  options: {},
  userReport: null,
  userGoalReport: null,
  preReportsList: [],
  reportSigned: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_TYPES.RESET_STATE:
      return initialState;

    case USER_TYPES.GET_LIST_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.GET_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        list: action.payload.data,
      };

    case USER_TYPES.GET_LIST_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.GET_DETAILS_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.GET_DETAILS_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        details: action.payload.data,
      };

    case USER_TYPES.GET_DETAILS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.UPDATE_USER_BEGIN:
      return {
        ...state,
        updating: true,
        error: null,
      };

    case USER_TYPES.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updating: false,
        error: null,
      };

    case USER_TYPES.UPDATE_USER_FAILURE:
      return {
        ...state,
        updating: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.DELETE_USER_BEGIN:
      return {
        ...state,
        updating: true,
        error: null,
      };

    case USER_TYPES.DELETE_USER_SUCCESS:
      return {
        ...state,
        updating: false,
        error: null,
      };

    case USER_TYPES.DELETE_USER_FAILURE:
      return {
        ...state,
        updating: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.GET_USER_GOALS_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.GET_USER_GOALS_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        goalsData: action.payload,
      };

    case USER_TYPES.GET_USER_GOALS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.CREATE_USER_REPORT_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.CREATE_USER_REPORT_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        userReport: action.payload,
      };

    case USER_TYPES.CREATE_USER_REPORT_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.CREATE_USER_REPORT_GOAL_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.CREATE_USER_REPORT_GOAL_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        userGoalReport: action.payload,
      };

    case USER_TYPES.CREATE_USER_REPORT_GOAL_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.GET_USER_TIMELINE_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.GET_USER_TIMELINE_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        timelineData: action.payload,
      };

    case USER_TYPES.GET_USER_TIMELINE_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.GET_GENERATED_REPORTS_LIST_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.GET_GENERATED_REPORTS_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        preReportsList: action.payload,
      };

    case USER_TYPES.GET_GENERATED_REPORTS_LIST_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.GET_REPORTS_SIGNED_URL_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.GET_REPORTS_SIGNED_URL_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        reportSigned: action.payload,
      };

    case USER_TYPES.GET_REPORTS_SIGNED_URL_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };

    case USER_TYPES.RESET_FILTERS:
      return {
        ...state,
        filters: initialFilters.map((item) => {
          item.value = null;
          return item;
        }),
      };

    default:
      return state;
  }
}
