const NOTIFICATION_SETTING_TYPES = {
  DAILY_REMINDER_BEGIN: "DAILY_REMINDER_BEGIN",
  DAILY_REMINDER_SUCCESS: "DAILY_REMINDER_SUCCESS",
  DAILY_REMINDER_FAILURE: "DAILY_REMINDER_FAILURE",

  DAILY_REMINDER_LIST_BEGIN: "DAILY_REMINDER_LIST_BEGIN",
  DAILY_REMINDER_LIST_SUCCESS: "DAILY_REMINDER_LIST_SUCCESS",
  DAILY_REMINDER_LIST_FAILURE: "DAILY_REMINDER_LIST_FAILURE",

  INACTIVITY_BEGIN: "INACTIVITY_BEGIN",
  INACTIVITY_SUCCESS: "INACTIVITY_SUCCESS",
  INACTIVITY_FAILURE: "INACTIVITY_FAILURE",

  INACTIVITY_LIST_BEGIN: "INACTIVITY_LIST_BEGIN",
  INACTIVITY_LIST_SUCCESS: "INACTIVITY_LIST_SUCCESS",
  INACTIVITY_LIST_FAILURE: "INACTIVITY_LIST_FAILURE",

  LOGGED_OUT_BEGIN: "LOGGED_OUT_BEGIN",
  LOGGED_OUT_SUCCESS: "LOGGED_OUT_SUCCESS",
  LOGGED_OUT_FAILURE: "LOGGED_OUT_FAILURE",

  LOGGED_OUT_LIST_BEGIN: "LOGGED_OUT_LIST_BEGIN",
  LOGGED_OUT_LIST_SUCCESS: "LOGGED_OUT_LIST_SUCCESS",
  LOGGED_OUT_LIST_FAILURE: "LOGGED_OUT_LIST_FAILURE",

  TRAVEL_BEHAVIOR_BEGIN: "TRAVEL_BEHAVIOR_BEGIN",
  TRAVEL_BEHAVIOR_SUCCESS: "TRAVEL_BEHAVIOR_SUCCESS",
  TRAVEL_BEHAVIOR_FAILURE: "TRAVEL_BEHAVIOR_FAILURE",

  TRAVEL_BEHAVIOR_LIST_BEGIN: "TRAVEL_BEHAVIOR_LIST_BEGIN",
  TRAVEL_BEHAVIOR_LIST_SUCCESS: "TRAVEL_BEHAVIOR_LIST_SUCCESS",
  TRAVEL_BEHAVIOR_LIST_FAILURE: "TRAVEL_BEHAVIOR_LIST_FAILURE",

  RELEASE_BEGIN: "RELEASE_BEGIN",
  RELEASE_SUCCESS: "RELEASE_SUCCESS",
  RELEASE_FAILURE: "RELEASE_FAILURE",
};

export default NOTIFICATION_SETTING_TYPES;
