import TEAM_TYPES from "../types/teamTypes";

const initialFilters = Object.seal([
  {
    name: "role",
    type: "select",
    placeHolder: "Select Role",
    options: [],
    value: null,
  },
  {
    name: "status",
    type: "select",
    placeHolder: "Select Status",
    options: [
      {
        value: 2,
        label: "Active",
      },
      {
        value: 3,
        label: "Blocked",
      },
      {
        value: 1,
        label: "Pending Invite",
      },
    ],
    value: null,
  },
  {
    name: "createdAt",
    type: "daterange",
    label: "Date Added",
    value: null,
  },
]);

const initialState = {
  fetching: false,
  submitting: false,
  verifingInvite: false,
  error: null,
  list: [],
  filters: initialFilters,
  details: null,
};

export default function teamReducer(state = initialState, action) {
  switch (action.type) {
    case TEAM_TYPES.RESET_STATE:
      return initialState;

    case TEAM_TYPES.GET_LIST_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
        details: null,
      };

    case TEAM_TYPES.GET_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        list: action.payload.data,
      };

    case TEAM_TYPES.GET_LIST_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case TEAM_TYPES.GET_DETAILS_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case TEAM_TYPES.GET_DETAILS_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        details: action.payload.data,
      };

    case TEAM_TYPES.GET_DETAILS_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case TEAM_TYPES.VERIFY_INVITE_BEGIN:
      return {
        ...state,
        verifingInvite: true,
        error: null,
      };

    case TEAM_TYPES.VERIFY_INVITE_SUCCESS:
      return {
        ...state,
        verifingInvite: false,
        error: null,
      };

    case TEAM_TYPES.VERIFY_INVITE_FAILURE:
      return {
        ...state,
        verifingInvite: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case TEAM_TYPES.ADD_MEMBER_BEGIN:
    case TEAM_TYPES.UPDATE_MEMBER_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case TEAM_TYPES.ADD_MEMBER_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
      };

    case TEAM_TYPES.UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
      };

    case TEAM_TYPES.ADD_MEMBER_FAILURE:
    case TEAM_TYPES.UPDATE_MEMBER_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case TEAM_TYPES.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };

    case TEAM_TYPES.RESET_FILTERS:
      return {
        ...state,
        filters: initialFilters.map((item) => {
          item.value = null;
          return item;
        }),
      };

    case TEAM_TYPES.SET_SUBMITTING:
      return {
        ...state,
        submitting: action.payload,
      };

    default:
      return state;
  }
}
