import NOTIFICATION_SETTING_TYPES from '../types/notificationSettingTypes';

const initialState = {
  fetching: false,
  dailyReminderFetching: false,
  submitting: false,
  submittingDailyReminder: false,
  dailyReminderData: null,

  fetchingInactivity: false,
  submittingInactivity: false,
  inactivityData: null,

  fetchingLoggedOut: false,
  submittingLoggedOut: false,
  loggedOutData: null,

  fetchingTravelBehavior: false,
  submittingTravelBehavior: false,
  travelBehaviorData: null,

  submittingNewRelease: false,
};

export default function notificationSettingReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case NOTIFICATION_SETTING_TYPES.DAILY_REMINDER_BEGIN:
      return {
        ...state,
        submittingDailyReminder: true,
        error: null,
      };

    case NOTIFICATION_SETTING_TYPES.DAILY_REMINDER_SUCCESS:
      return {
        ...state,
        submittingDailyReminder: false,
        error: null,
      };

    case NOTIFICATION_SETTING_TYPES.DAILY_REMINDER_FAILURE:
      return {
        ...state,
        submittingDailyReminder: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case NOTIFICATION_SETTING_TYPES.DAILY_REMINDER_LIST_BEGIN:
      return {
        ...state,
        dailyReminderFetching: true,
        error: null,
      };

    case NOTIFICATION_SETTING_TYPES.DAILY_REMINDER_LIST_SUCCESS:
      return {
        ...state,
        dailyReminderFetching: false,
        error: null,
        dailyReminderData: action.payload.data,
      };

    case NOTIFICATION_SETTING_TYPES.DAILY_REMINDER_LIST_FAILURE:
      return {
        ...state,
        dailyReminderFetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case NOTIFICATION_SETTING_TYPES.INACTIVITY_BEGIN:
      return {
        ...state,
        submittingInactivity: true,
        error: null,
      };

    case NOTIFICATION_SETTING_TYPES.INACTIVITY_SUCCESS:
      return {
        ...state,
        submittingInactivity: false,
        error: null,
      };

    case NOTIFICATION_SETTING_TYPES.INACTIVITY_FAILURE:
      return {
        ...state,
        submittingInactivity: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case NOTIFICATION_SETTING_TYPES.INACTIVITY_LIST_BEGIN:
      return {
        ...state,
        fetchingInactivity: true,
        error: null,
      };

    case NOTIFICATION_SETTING_TYPES.INACTIVITY_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        fetchingInactivity: false,
        inactivityData: action.payload.data,
      };

    case NOTIFICATION_SETTING_TYPES.INACTIVITY_LIST_FAILURE:
      return {
        ...state,
        fetchingInactivity: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case NOTIFICATION_SETTING_TYPES.LOGGED_OUT_BEGIN:
      return {
        ...state,
        submittingLoggedOut: true,
        error: null,
      };

    case NOTIFICATION_SETTING_TYPES.LOGGED_OUT_SUCCESS:
      return {
        ...state,
        submittingLoggedOut: false,
        error: null,
      };

    case NOTIFICATION_SETTING_TYPES.LOGGED_OUT_FAILURE:
      return {
        ...state,
        submittingLoggedOut: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case NOTIFICATION_SETTING_TYPES.LOGGED_OUT_LIST_BEGIN:
      return {
        ...state,
        fetchingLoggedOut: true,
        error: null,
      };

    case NOTIFICATION_SETTING_TYPES.LOGGED_OUT_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        fetchingLoggedOut: false,
        loggedOutData: action.payload.data,
      };

    case NOTIFICATION_SETTING_TYPES.LOGGED_OUT_LIST_FAILURE:
      return {
        ...state,
        fetchingLoggedOut: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case NOTIFICATION_SETTING_TYPES.TRAVEL_BEHAVIOR_BEGIN:
      return {
        ...state,
        submittingTravelBehavior: true,
        error: null,
      };

    case NOTIFICATION_SETTING_TYPES.TRAVEL_BEHAVIOR_SUCCESS:
      return {
        ...state,
        submittingTravelBehavior: false,
        error: null,
      };

    case NOTIFICATION_SETTING_TYPES.TRAVEL_BEHAVIOR_FAILURE:
      return {
        ...state,
        submittingTravelBehavior: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case NOTIFICATION_SETTING_TYPES.TRAVEL_BEHAVIOR_LIST_BEGIN:
      return {
        ...state,
        fetchingTravelBehavior: true,
        error: null,
      };

    case NOTIFICATION_SETTING_TYPES.TRAVEL_BEHAVIOR_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        fetchingTravelBehavior: false,
        travelBehaviorData: action.payload.data,
      };

    case NOTIFICATION_SETTING_TYPES.TRAVEL_BEHAVIOR_LIST_FAILURE:
      return {
        ...state,
        fetchingTravelBehavior: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };


    case NOTIFICATION_SETTING_TYPES.RELEASE_BEGIN:
      return {
        ...state,
        submittingNewRelease: true,
        error: null,
      };

    case NOTIFICATION_SETTING_TYPES.RELEASE_SUCCESS:
      return {
        ...state,
        submittingNewRelease: false,
        error: null,
      };

    case NOTIFICATION_SETTING_TYPES.RELEASE_FAILURE:
      return {
        ...state,
        submittingNewRelease: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    default:
      return state;
  }
}
