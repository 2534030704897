const GOAL_TYPES = {
  RESET_STATE: 'RESET_STATE',

  GET_LIST_BEGIN: 'GET_GOAL_LIST_BEGIN',
  GET_LIST_SUCCESS: 'GET_GOAL_LIST_SUCCESS',
  GET_LIST_FAILURE: 'GET_GOAL_LIST_FAILURE',

  GET_TIMELINE_BEGIN: 'GET_GOAL_TIMELINE_BEGIN',
  GET_TIMELINE_SUCCESS: 'GET_GOAL_TIMELINE_SUCCESS',
  GET_TIMELINE_FAILURE: 'GET_GOAL_TIMELINE_FAILURE',

  UPDATE_GOAL_BEGIN: 'UPDATE_GOAL_BEGIN',
  UPDATE_GOAL_SUCCESS: 'UPDATE_GOAL_SUCCESS',
  UPDATE_GOAL_FAILURE: 'UPDATE_GOAL_FAILURE',

  SET_FILTERS: 'SET_FILTERS',
  RESET_FILTERS: 'RESET_FILTERS',
};

export default GOAL_TYPES;
