const STAT_TYPES = {
  RESET_STATE: 'RESET_STATE',

  GET_GOAL_TYPE_BEGIN: 'GET_STAT_GOAL_TYPE_BEGIN',
  GET_GOAL_TYPE_SUCCESS: 'GET_STAT_GOAL_TYPE_SUCCESS',
  GET_GOAL_TYPE_FAILURE: 'GET_STAT_GOAL_TYPE_FAILURE',

  GET_GOAL_CATEGORY_BEGIN: 'GET_STAT_GOAL_CATEGORY_BEGIN',
  GET_GOAL_CATEGORY_SUCCESS: 'GET_STAT_GOAL_CATEGORY_SUCCESS',
  GET_GOAL_CATEGORY_FAILURE: 'GET_STAT_GOAL_CATEGORY_FAILURE',

  GET_GOAL_STATUS_BEGIN: 'GET_STAT_GOAL_STATUS_BEGIN',
  GET_GOAL_STATUS_SUCCESS: 'GET_STAT_GOAL_STATUS_SUCCESS',
  GET_GOAL_STATUS_FAILURE: 'GET_STAT_GOAL_STATUS_FAILURE',

  GET_USER_ACTIVITY_BEGIN: 'GET_STAT_USER_ACTIVITY_BEGIN',
  GET_USER_ACTIVITY_SUCCESS: 'GET_STAT_USER_ACTIVITY_SUCCESS',
  GET_USER_ACTIVITY_FAILURE: 'GET_STAT_USER_ACTIVITY_FAILURE',

  GET_USER_ANALYSIS_BEGIN: 'GET_STAT_USER_ANALYSIS_BEGIN',
  GET_USER_ANALYSIS_SUCCESS: 'GET_STAT_USER_ANALYSIS_SUCCESS',
  GET_USER_ANALYSIS_FAILURE: 'GET_STAT_USER_ANALYSIS_FAILURE',

  GET_USER_PROFESSION_BEGIN: 'GET_STAT_USER_PROFESSION_BEGIN',
  GET_USER_PROFESSION_SUCCESS: 'GET_STAT_USER_PROFESSION_SUCCESS',
  GET_USER_PROFESSION_FAILURE: 'GET_STAT_USER_PROFESSION_FAILURE',

  GET_ACTIVE_USER_FREQUENCY_BEGIN: 'GET_STAT_ACTIVE_USER_FREQUENCY_BEGIN',
  GET_ACTIVE_USER_FREQUENCY_SUCCESS: 'GET_STAT_ACTIVE_USER_FREQUENCY_SUCCESS',
  GET_ACTIVE_USER_FREQUENCY_FAILURE: 'GET_STAT_ACTIVE_USER_FREQUENCY_FAILURE',

  GET_USER_GROWTH_OS_BEGIN: 'GET_STAT_USER_GROWTH_OS_BEGIN',
  GET_USER_GROWTH_OS_SUCCESS: 'GET_STAT_USER_GROWTH_OS_SUCCESS',
  GET_USER_GROWTH_OS_FAILURE: 'GET_STAT_USER_GROWTH_OS_FAILURE',

  GET_USER_PRESENCE_OS_BEGIN: 'GET_STAT_USER_PRESENCE_OS_BEGIN',
  GET_USER_PRESENCE_OS_SUCCESS: 'GET_STAT_USER_PRESENCE_OS_SUCCESS',
  GET_USER_PRESENCE_OS_FAILURE: 'GET_STAT_USER_PRESENCE_OS_FAILURE',

  GET_USER_NOTIFICATION_BEGIN: 'GET_STAT_USER_NOTIFICATION_BEGIN',
  GET_USER_NOTIFICATION_SUCCESS: 'GET_STAT_USER_NOTIFICATION_SUCCESS',
  GET_USER_NOTIFICATION_FAILURE: 'GET_STAT_USER_NOTIFICATION_FAILURE',

  GET_USER_TRAVEL_BEGIN: 'GET_STAT_USER_TRAVEL_BEGIN',
  GET_USER_TRAVEL_SUCCESS: 'GET_STAT_USER_TRAVEL_SUCCESS',
  GET_USER_TRAVEL_FAILURE: 'GET_STAT_USER_TRAVEL_FAILURE',

  GET_USER_BIOMETRIC_BEGIN: 'GET_STAT_USER_BIOMETRIC_BEGIN',
  GET_USER_BIOMETRIC_SUCCESS: 'GET_STAT_USER_BIOMETRIC_SUCCESS',
  GET_USER_BIOMETRIC_FAILURE: 'GET_STAT_USER_BIOMETRIC_FAILURE',

  GET_USER_GROWTH_COUNTRY_BEGIN: 'GET_STAT_USER_GROWTH_COUNTRY_BEGIN',
  GET_USER_GROWTH_COUNTRY_SUCCESS: 'GET_STAT_USER_GROWTH_COUNTRY_SUCCESS',
  GET_USER_GROWTH_COUNTRY_FAILURE: 'GET_STAT_USER_GROWTH_COUNTRY_FAILURE',

  GET_USER_LOCATION_BEGIN: 'GET_STAT_USER_LOCATION_BEGIN',
  GET_USER_LOCATION_SUCCESS: 'GET_STAT_USER_LOCATION_SUCCESS',
  GET_USER_LOCATION_FAILURE: 'GET_STAT_USER_LOCATION_FAILURE',

  GET_USER_GOAL_BY_COUNTRY_BEGIN: 'GET_USER_GOAL_BY_COUNTRY_BEGIN',
  GET_USER_GOAL_BY_COUNTRY_SUCCESS: 'GET_USER_GOAL_BY_COUNTRY_SUCCESS',
  GET_USER_GOAL_BY_COUNTRY_FAILURE: 'GET_USER_GOAL_BY_COUNTRY_FAILURE',

  GET_USER_GOAL_BY_STATE_BEGIN: 'GET_USER_GOAL_BY_STATE_BEGIN',
  GET_USER_GOAL_BY_STATE_SUCCESS: 'GET_USER_GOAL_BY_STATE_SUCCESS',
  GET_USER_GOAL_BY_STATE_FAILURE: 'GET_USER_GOAL_BY_STATE_FAILURE',

  GET_USER_GOAL_BY_CITY_BEGIN: 'GET_USER_GOAL_BY_CITY_BEGIN',
  GET_USER_GOAL_BY_CITY_SUCCESS: 'GET_USER_GOAL_BY_CITY_SUCCESS',
  GET_USER_GOAL_BY_CITY_FAILURE: 'GET_USER_GOAL_BY_CITY_FAILURE',

  GET_USER_GROWTH_CONVERSION_BEGIN: 'GET_USER_GROWTH_CONVERSION_BEGIN',
  GET_USER_GROWTH_CONVERSION_SUCCESS: 'GET_USER_GROWTH_CONVERSION_SUCCESS',
  GET_USER_GROWTH_CONVERSION_FAILURE: 'GET_USER_GROWTH_CONVERSION_FAILURE',

  GET_CONVERTED_USER_BY_GOAL_COUNT_BEGIN: 'GET_CONVERTED_USER_BY_GOAL_COUNT_BEGIN',
  GET_CONVERTED_USER_BY_GOAL_COUNT_SUCCESS: 'GET_CONVERTED_USER_BY_GOAL_COUNT_SUCCESS',
  GET_CONVERTED_USER_BY_GOAL_COUNT_FAILURE: 'GET_CONVERTED_USER_BY_GOAL_COUNT_FAILURE',

  GET_CONVERTED_USER_BY_GOAL_TYPE_BEGIN: 'GET_CONVERTED_USER_BY_GOAL_TYPE_BEGIN',
  GET_CONVERTED_USER_BY_GOAL_TYPE_SUCCESS: 'GET_CONVERTED_USER_BY_GOAL_TYPE_SUCCESS',
  GET_CONVERTED_USER_BY_GOAL_TYPE_FAILURE: 'GET_CONVERTED_USER_BY_GOAL_TYPE_FAILURE',

  GET_CONVERTED_USER_BY_OS_BEGIN: 'GET_CONVERTED_USER_BY_OS_BEGIN',
  GET_CONVERTED_USER_BY_OS_SUCCESS: 'GET_CONVERTED_USER_BY_OS_SUCCESS',
  GET_CONVERTED_USER_BY_OS_FAILURE: 'GET_CONVERTED_USER_BY_OS_FAILURE',
};

export default STAT_TYPES;
