import ROLE_TYPES from "../types/roleTypes";

const initialFilters = Object.seal([
  {
    name: "isActive",
    type: "select",
    placeHolder: "Select Status",
    options: [
      {
        value: "true",
        label: "Active",
      },
      {
        value: "false",
        label: "Disabled",
      },
    ],
    value: null,
  },
]);

const initialState = {
  fetching: false,
  submitting: false,
  error: null,
  list: [],
  filters: initialFilters,
  options: {},
};

export default function roleReducer(state = initialState, action) {
  switch (action.type) {
    case ROLE_TYPES.RESET_STATE:
      return initialState;

    case ROLE_TYPES.GET_LIST_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case ROLE_TYPES.GET_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        list: action.payload.data,
      };

    case ROLE_TYPES.GET_LIST_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case ROLE_TYPES.CREATE_ROLE_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case ROLE_TYPES.CREATE_ROLE_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
      };

    case ROLE_TYPES.CREATE_ROLE_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case ROLE_TYPES.UPDATE_ROLE_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case ROLE_TYPES.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
      };

    case ROLE_TYPES.UPDATE_ROLE_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case ROLE_TYPES.DELETE_ROLE_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case ROLE_TYPES.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
      };

    case ROLE_TYPES.DELETE_ROLE_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case ROLE_TYPES.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };

    case ROLE_TYPES.RESET_FILTERS:
      return {
        ...state,
        filters: initialFilters.map((item) => {
          item.value = null;
          return item;
        }),
      };

    default:
      return state;
  }
}
