const ACCOUNT_TYPES = {
  RESET_STATE: "RESET_STATE",

  UPDATE_PROFILE_BEGIN: "UPDATE_PROFILE_BEGIN",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",

  UPDATE_PASSWORD_BEGIN: "UPDATE_PASSWORD_BEGIN",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE"
};

export default ACCOUNT_TYPES;