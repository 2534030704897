import GOAL_TYPES from '../types/goalTypes';
import Constants from '../../utils/constants';

const { GOAL } = Constants;

const initialFilters = Object.seal([
  {
    name: 'category',
    type: 'select',
    placeHolder: 'Select Category',
    options: [
      {
        label: GOAL.CATEGORIES.ESTABLISH_RESIDENCY,
      },
      {
        label: GOAL.CATEGORIES.VISA_DURATION,
      },
      {
        label: GOAL.CATEGORIES.CUSTOM_GOAL,
      },
    ],
    value: null,
  },
  {
    name: 'type',
    type: 'select',
    placeHolder: 'Select Goal Type',
    options: [
      {
        label: GOAL.TYPES.ACHIEVE,
      },
      {
        label: GOAL.TYPES.AVOID,
      },
    ],
    value: null,
  },
  {
    name: 'status',
    type: 'select',
    placeHolder: 'Select Status',
    options: [
      {
        label: GOAL.STATUS.IN_PROGRESS,
      },
      {
        label: GOAL.STATUS.COMPLETED,
      },
      {
        label: GOAL.STATUS.INCOMPLETE,
      },
      {
        label: GOAL.STATUS.AT_RISK,
      },
      {
        label: GOAL.STATUS.DELETED,
      },
      {
        label: GOAL.STATUS.ARCHIVED,
      },
    ],
    value: null,
  },
  {
    name: 'createdAt',
    type: 'daterange',
    label: 'Date Added',
    value: null,
  },
]);

const initialState = {
  fetching: false,
  submitting: false,
  error: null,
  list: [],
  timelineData: [],
  fetchingTimeline: false,
  filters: initialFilters,
  options: {},
};

export default function goalReducer(state = initialState, action) {
  switch (action.type) {
    case GOAL_TYPES.RESET_STATE:
      return initialState;

    case GOAL_TYPES.GET_LIST_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case GOAL_TYPES.GET_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        list: action.payload.data,
      };

    case GOAL_TYPES.GET_LIST_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case GOAL_TYPES.GET_TIMELINE_BEGIN:
      return {
        ...state,
        fetchingTimeline: true,
        error: null,
      };

    case GOAL_TYPES.GET_TIMELINE_SUCCESS:
      return {
        ...state,
        fetchingTimeline: false,
        error: null,
        timelineData: action.payload,
      };

    case GOAL_TYPES.GET_TIMELINE_FAILURE:
      return {
        ...state,
        fetchingTimeline: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case GOAL_TYPES.UPDATE_GOAL_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case GOAL_TYPES.UPDATE_GOAL_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
      };

    case GOAL_TYPES.UPDATE_GOAL_FAILURE:
      return {
        ...state,
        submitting: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case GOAL_TYPES.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };

    case GOAL_TYPES.RESET_FILTERS:
      return {
        ...state,
        filters: initialFilters.map((item) => {
          item.value = null;
          return item;
        }),
      };

    default:
      return state;
  }
}
