const SETTING_TYPES = {
  RESET_STATE: "RESET_STATE",

  GET_COUNTRY_LIST_BEGIN: "GET_COUNTRY_LIST_BEGIN",
  GET_COUNTRY_LIST_SUCCESS: "GET_COUNTRY_LIST_SUCCESS",
  GET_COUNTRY_LIST_FAILURE: "GET_COUNTRY_LIST_FAILURE",

  GET_BUCKET_DATA_BEGIN: "GET_BUCKET_DATA_BEGIN",
  GET_BUCKET_DATA_SUCCESS: "GET_BUCKET_DATA_SUCCESS",
  GET_BUCKET_DATA_FAILURE: "GET_BUCKET_DATA_FAILURE",

  GET_BUCKET_DISTANCE_LIST_BEGIN: "GET_BUCKET_DISTANCE_LIST_BEGIN",
  GET_BUCKET_DISTANCE_LIST_SUCCESS: "GET_BUCKET_DISTANCE_LIST_SUCCESS",
  GET_BUCKET_DISTANCE_LIST_FAILURE: "GET_BUCKET_DISTANCE_LIST_FAILURE",

  GET_BUCKET_DATE_LIST_BEGIN: "GET_BUCKET_DATE_LIST_BEGIN",
  GET_BUCKET_DATE_LIST_SUCCESS: "GET_BUCKET_DATE_LIST_SUCCESS",
  GET_BUCKET_DATE_LIST_FAILURE: "GET_BUCKET_DATE_LIST_FAILURE",

  GET_STATE_LIST_BEGIN: "GET_STATE_LIST_BEGIN",
  GET_STATE_LIST_SUCCESS: "GET_STATE_LIST_SUCCESS",
  GET_STATE_LIST_FAILURE: "GET_STATE_LIST_FAILURE",

  GET_CITY_LIST_BEGIN: "GET_CITY_LIST_BEGIN",
  GET_CITY_LIST_SUCCESS: "GET_CITY_LIST_SUCCESS",
  GET_CITY_LIST_FAILURE: "GET_CITY_LIST_FAILURE",

  GET_REGION_LIST_BEGIN: "GET_REGION_LIST_BEGIN",
  GET_REGION_LIST_SUCCESS: "GET_REGION_LIST_SUCCESS",
  GET_REGION_LIST_FAILURE: "GET_REGION_LIST_FAILURE",

  GET_TAG_LIST_BEGIN: "GET_TAG_LIST_BEGIN",
  GET_TAG_LIST_SUCCESS: "GET_TAG_LIST_SUCCESS",
  GET_TAG_LIST_FAILURE: "GET_TAG_LIST_FAILURE",

  GET_PLAN_IOS_LIST_BEGIN: "GET_PLAN_IOS_LIST_BEGIN",
  GET_PLAN_IOS_LIST_SUCCESS: "GET_PLAN_IOS_LIST_SUCCESS",
  GET_PLAN_IOS_LIST_FAILURE: "GET_PLAN_IOS_LIST_FAILURE",

  GET_PLAN_ANDROID_LIST_BEGIN: "GET_PLAN_ANDROID_LIST_BEGIN",
  GET_PLAN_ANDROID_LIST_SUCCESS: "GET_PLAN_ANDROID_LIST_SUCCESS",
  GET_PLAN_ANDROID_LIST_FAILURE: "GET_PLAN_ANDROID_LIST_FAILURE",

  GET_AVAIL_COUNTRY_LIST_BEGIN: "GET_AVAIL_COUNTRY_LIST_BEGIN",
  GET_AVAIL_COUNTRY_LIST_SUCCESS: "GET_AVAIL_COUNTRY_LIST_SUCCESS",
  GET_AVAIL_COUNTRY_LIST_FAILURE: "GET_AVAIL_COUNTRY_LIST_FAILURE",

  ADD_COUNTRY_BEGIN: "ADD_COUNTRY_BEGIN",
  ADD_COUNTRY_SUCCESS: "ADD_COUNTRY_SUCCESS",
  ADD_COUNTRY_FAILURE: "ADD_COUNTRY_FAILURE",

  ADD_STATE_BEGIN: "ADD_STATE_BEGIN",
  ADD_STATE_SUCCESS: "ADD_STATE_SUCCESS",
  ADD_STATE_FAILURE: "ADD_STATE_FAILURE",

  ADD_CITY_BEGIN: "ADD_CITY_BEGIN",
  ADD_CITY_SUCCESS: "ADD_CITY_SUCCESS",
  ADD_CITY_FAILURE: "ADD_CITY_FAILURE",

  ADD_REGION_BEGIN: "ADD_REGION_BEGIN",
  ADD_REGION_SUCCESS: "ADD_REGION_SUCCESS",
  ADD_REGION_FAILURE: "ADD_REGION_FAILURE",

  ADD_TAG_BEGIN: "ADD_TAG_BEGIN",
  ADD_TAG_SUCCESS: "ADD_TAG_SUCCESS",
  ADD_TAG_FAILURE: "ADD_TAG_FAILURE",

  ADD_THRESHOLD_BEGIN: "ADD_THRESHOLD_BEGIN",
  ADD_THRESHOLD_SUCCESS: "ADD_THRESHOLD_SUCCESS",
  ADD_THRESHOLD_FAILURE: "ADD_THRESHOLD_FAILURE",

  ADD_AVAIL_COUNTRY_BEGIN: "ADD_AVAIL_COUNTRY_BEGIN",
  ADD_AVAIL_COUNTRY_SUCCESS: "ADD_AVAIL_COUNTRY_SUCCESS",
  ADD_AVAIL_COUNTRY_FAILURE: "ADD_AVAIL_COUNTRY_FAILURE",

  ADD_PLAN_IOS_BEGIN: "ADD_PLAN_IOS_BEGIN",
  ADD_PLAN_IOS_SUCCESS: "ADD_PLAN_IOS_SUCCESS",
  ADD_PLAN_IOS_FAILURE: "ADD_PLAN_IOS_FAILURE",

  ADD_PLAN_ANDROID_BEGIN: "ADD_PLAN_ANDROID_BEGIN",
  ADD_PLAN_ANDROID_SUCCESS: "ADD_PLAN_ANDROID_SUCCESS",
  ADD_PLAN_ANDROID_FAILURE: "ADD_PLAN_ANDROID_FAILURE",

  UPDATE_BUCKET_LIST_BEGIN: "UPDATE_BUCKET_LIST_BEGIN",
  UPDATE_BUCKET_LIST_SUCCESS: "UPDATE_BUCKET_LIST_SUCCESS",
  UPDATE_BUCKET_LIST_FAILURE: "UPDATE_BUCKET_LIST_FAILURE",

  UPDATE_COUNTRY_BEGIN: "UPDATE_COUNTRY_BEGIN",
  UPDATE_COUNTRY_SUCCESS: "UPDATE_COUNTRY_SUCCESS",
  UPDATE_COUNTRY_FAILURE: "UPDATE_COUNTRY_FAILURE",

  UPDATE_STATE_BEGIN: "UPDATE_STATE_BEGIN",
  UPDATE_STATE_SUCCESS: "UPDATE_STATE_SUCCESS",
  UPDATE_STATE_FAILURE: "UPDATE_STATE_FAILURE",

  UPDATE_CITY_BEGIN: "UPDATE_CITY_BEGIN",
  UPDATE_CITY_SUCCESS: "UPDATE_CITY_SUCCESS",
  UPDATE_CITY_FAILURE: "UPDATE_CITY_FAILURE",

  UPDATE_REGION_BEGIN: "UPDATE_REGION_BEGIN",
  UPDATE_REGION_SUCCESS: "UPDATE_REGION_SUCCESS",
  UPDATE_REGION_FAILURE: "UPDATE_REGION_FAILURE",

  UPDATE_TAG_BEGIN: "UPDATE_TAG_BEGIN",
  UPDATE_TAG_SUCCESS: "UPDATE_TAG_SUCCESS",
  UPDATE_TAG_FAILURE: "UPDATE_TAG_FAILURE",

  UPDATE_THRESHOLD_BEGIN: "UPDATE_THRESHOLD_BEGIN",
  UPDATE_THRESHOLD_SUCCESS: "UPDATE_THRESHOLD_SUCCESS",
  UPDATE_THRESHOLD_FAILURE: "UPDATE_THRESHOLD_FAILURE",

  UPDATE_PLAN_IOS_BEGIN: "UPDATE_PLAN_IOS_BEGIN",
  UPDATE_PLAN_IOS_SUCCESS: "UPDATE_PLAN_IOS_SUCCESS",
  UPDATE_PLAN_IOS_FAILURE: "UPDATE_PLAN_IOS_FAILURE",

  UPDATE_PLAN_ANDROID_BEGIN: "UPDATE_PLAN_ANDROID_BEGIN",
  UPDATE_PLAN_ANDROID_SUCCESS: "UPDATE_PLAN_ANDROID_SUCCESS",
  UPDATE_PLAN_ANDROID_FAILURE: "UPDATE_PLAN_ANDROID_FAILURE",

  UPDATE_AVAIL_COUNTRY_BEGIN: "UPDATE_AVAIL_COUNTRY_BEGIN",
  UPDATE_AVAIL_COUNTRY_SUCCESS: "UPDATE_AVAIL_COUNTRY_SUCCESS",
  UPDATE_AVAIL_COUNTRY_FAILURE: "UPDATE_AVAIL_COUNTRY_FAILURE",

  DELETE_COUNTRY_BEGIN: "DELETE_COUNTRY_BEGIN",
  DELETE_COUNTRY_SUCCESS: "DELETE_COUNTRY_SUCCESS",
  DELETE_COUNTRY_FAILURE: "DELETE_COUNTRY_FAILURE",

  DELETE_STATE_BEGIN: "DELETE_STATE_BEGIN",
  DELETE_STATE_SUCCESS: "DELETE_STATE_SUCCESS",
  DELETE_STATE_FAILURE: "DELETE_STATE_FAILURE",

  DELETE_CITY_BEGIN: "DELETE_CITY_BEGIN",
  DELETE_CITY_SUCCESS: "DELETE_CITY_SUCCESS",
  DELETE_CITY_FAILURE: "DELETE_CITY_FAILURE",

  DELETE_REGION_BEGIN: "DELETE_REGION_BEGIN",
  DELETE_REGION_SUCCESS: "DELETE_REGION_SUCCESS",
  DELETE_REGION_FAILURE: "DELETE_REGION_FAILURE",

  DELETE_TAG_BEGIN: "DELETE_TAG_BEGIN",
  DELETE_TAG_SUCCESS: "DELETE_TAG_SUCCESS",
  DELETE_TAG_FAILURE: "DELETE_TAG_FAILURE",

  DELETE_THRESHOLD_BEGIN: "DELETE_THRESHOLD_BEGIN",
  DELETE_THRESHOLD_SUCCESS: "DELETE_THRESHOLD_SUCCESS",
  DELETE_THRESHOLD_FAILURE: "DELETE_THRESHOLD_FAILURE",

  DELETE_PLAN_IOS_BEGIN: "DELETE_PLAN_IOS_BEGIN",
  DELETE_PLAN_IOS_SUCCESS: "DELETE_PLAN_IOS_SUCCESS",
  DELETE_PLAN_IOS_FAILURE: "DELETE_PLAN_IOS_FAILURE",

  DELETE_PLAN_ANDROID_BEGIN: "DELETE_PLAN_ANDROID_BEGIN",
  DELETE_PLAN_ANDROID_SUCCESS: "DELETE_PLAN_ANDROID_SUCCESS",
  DELETE_PLAN_ANDROID_FAILURE: "DELETE_PLAN_ANDROID_FAILURE",

  DELETE_AVAIL_COUNTRY_BEGIN: "DELETE_AVAIL_COUNTRY_BEGIN",
  DELETE_AVAIL_COUNTRY_SUCCESS: "DELETE_AVAIL_COUNTRY_SUCCESS",
  DELETE_AVAIL_COUNTRY_FAILURE: "DELETE_AVAIL_COUNTRY_FAILURE",

  IMPORT_COUNTRY_LIST_BEGIN: "IMPORT_COUNTRY_LIST_BEGIN",
  IMPORT_COUNTRY_LIST_SUCCESS: "IMPORT_COUNTRY_LIST_SUCCESS",
  IMPORT_COUNTRY_LIST_FAILURE: "IMPORT_COUNTRY_LIST_FAILURE",

  IMPORT_COUNTRY_THRESHOLD_LIST_BEGIN: "IMPORT_COUNTRY_THRESHOLD_LIST_BEGIN",
  IMPORT_COUNTRY_THRESHOLD_LIST_SUCCESS: "IMPORT_COUNTRY_THRESHOLD_LIST_SUCCESS",
  IMPORT_COUNTRY_THRESHOLD_LIST_FAILURE: "IMPORT_COUNTRY_THRESHOLD_LIST_FAILURE",

  IMPORT_STATE_LIST_BEGIN: "IMPORT_STATE_LIST_BEGIN",
  IMPORT_STATE_LIST_SUCCESS: "IMPORT_STATE_LIST_SUCCESS",
  IMPORT_STATE_LIST_FAILURE: "IMPORT_STATE_LIST_FAILURE",

  IMPORT_STATE_THRESHOLD_LIST_BEGIN: "IMPORT_STATE_THRESHOLD_LIST_BEGIN",
  IMPORT_STATE_THRESHOLD_LIST_SUCCESS: "IMPORT_STATE_THRESHOLD_LIST_SUCCESS",
  IMPORT_STATE_THRESHOLD_LIST_FAILURE: "IMPORT_STATE_THRESHOLD_LIST_FAILURE",

  IMPORT_CITY_LIST_BEGIN: "IMPORT_CITY_LIST_BEGIN",
  IMPORT_CITY_LIST_SUCCESS: "IMPORT_CITY_LIST_SUCCESS",
  IMPORT_CITY_LIST_FAILURE: "IMPORT_CITY_LIST_FAILURE",

  IMPORT_CITY_THRESHOLD_LIST_BEGIN: "IMPORT_CITY_THRESHOLD_LIST_BEGIN",
  IMPORT_CITY_THRESHOLD_LIST_SUCCESS: "IMPORT_CITY_THRESHOLD_LIST_SUCCESS",
  IMPORT_CITY_THRESHOLD_LIST_FAILURE: "IMPORT_CITY_THRESHOLD_LIST_FAILURE",

  IMPORT_FORMAT_LIST_BEGIN: "IMPORT_FORMAT_LIST_BEGIN",
  IMPORT_FORMAT_LIST_SUCCESS: "IMPORT_FORMAT_LIST_SUCCESS",
  IMPORT_FORMAT_LIST_FAILURE: "IMPORT_FORMAT_LIST_FAILURE",

  EXPORT_FORMAT_LIST_BEGIN: "EXPORT_FORMAT_LIST_BEGIN",
  EXPORT_FORMAT_LIST_SUCCESS: "EXPORT_FORMAT_LIST_SUCCESS",
  EXPORT_FORMAT_LIST_FAILURE: "EXPORT_FORMAT_LIST_FAILURE",

  EXPORT_COUNTRY_LIST_BEGIN: "EXPORT_COUNTRY_LIST_BEGIN",
  EXPORT_COUNTRY_LIST_SUCCESS: "EXPORT_COUNTRY_LIST_SUCCESS",
  EXPORT_COUNTRY_LIST_FAILURE: "EXPORT_COUNTRY_LIST_FAILURE",

  EXPORT_COUNTRY_THRESHOLD_LIST_BEGIN: "EXPORT_COUNTRY_THRESHOLD_LIST_BEGIN",
  EXPORT_COUNTRY_THRESHOLD_LIST_SUCCESS: "EXPORT_COUNTRY_THRESHOLD_LIST_SUCCESS",
  EXPORT_COUNTRY_THRESHOLD_LIST_FAILURE: "EXPORT_COUNTRY_THRESHOLD_LIST_FAILURE",

  EXPORT_STATE_LIST_BEGIN: "EXPORT_STATE_LIST_BEGIN",
  EXPORT_STATE_LIST_SUCCESS: "EXPORT_STATE_LIST_SUCCESS",
  EXPORT_STATE_LIST_FAILURE: "EXPORT_STATE_LIST_FAILURE",

  EXPORT_STATE_THRESHOLD_LIST_BEGIN: "EXPORT_STATE_THRESHOLD_LIST_BEGIN",
  EXPORT_STATE_THRESHOLD_LIST_SUCCESS: "EXPORT_STATE_THRESHOLD_LIST_SUCCESS",
  EXPORT_STATE_THRESHOLD_LIST_FAILURE: "EXPORT_STATE_LTHRESHOLD_IST_FAILURE",

  EXPORT_CITY_LIST_BEGIN: "EXPORT_CITY_LIST_BEGIN",
  EXPORT_CITY_LIST_SUCCESS: "EXPORT_CITY_LIST_SUCCESS",
  EXPORT_CITY_LIST_FAILURE: "EXPORT_CITY_LIST_FAILURE",

  EXPORT_CITY_THRESHOLD_LIST_BEGIN: "EXPORT_CITY_THRESHOLD_LIST_BEGIN",
  EXPORT_CITY_THRESHOLD_LIST_SUCCESS: "EXPORT_CITY_THRESHOLD_LIST_SUCCESS",
  EXPORT_CITY_THRESHOLD_LIST_FAILURE: "EXPORT_CITY_THRESHOLD_LIST_FAILURE",

  RESET_EXPORT_DATA: "RESET_EXPORT_DATA",

  SET_FILTERS: "SET_FILTERS",
  RESET_FILTERS: "RESET_FILTERS",

  SET_FILTERS_AVAIL_COUNTRY: "SET_FILTERS_AVAIL_COUNTRY",
  RESET_FILTERS_AVAIL_COUNTRY: "RESET_FILTERS_AVAIL_COUNTRY",

  TRIAL_BEGIN: "TRIAL_BEGIN",
  TRIAL_SUCCESS: "TRIAL_SUCCESS",
  TRIAL_FAILURE: "TRIAL_FAILURE",

  UPDATE_TRIAL_BEGIN: "UPDATE_TRIAL_BEGIN",
  UPDATE_TRIAL_SUCCESS: "UPDATE_TRIAL_SUCCESS",
  UPDATE_TRIAL_FAILURE: "UPDATE_TRIAL_FAILURE",
};

export default SETTING_TYPES;
