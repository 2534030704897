import NOTIFICATION_TYPES from '../types/notificationTypes';
const initialFilters = Object.seal([
  {
    name: 'role',
    type: 'select',
    placeHolder: 'Select Role',
    options: [
      {
        value: 'Current User',
        label: 'Current User',
      },
      {
        value: 'All Users',
        label: 'All Users',
      },
    ],
    value: null,
  },
]);

const initialState = {
  fetching: false,
  usersFetching: false,
  list: null,
  receipients: [],
  usersList: null,
  submitting: false,
  submittingCampaign: false,
  createNotfication: null,
  filters: initialFilters,
};

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_TYPES.GET_NOTIFICATION_LIST_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case NOTIFICATION_TYPES.GET_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        list: action.payload.data,
      };

    case NOTIFICATION_TYPES.GET_NOTIFICATION_LIST_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case NOTIFICATION_TYPES.GET_USERS_LIST_BEGIN:
      return {
        ...state,
        usersFetching: true,
        error: null,
      };

    case NOTIFICATION_TYPES.GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        usersFetching: false,
        error: null,
        usersList: action.payload.data,
      };

    case NOTIFICATION_TYPES.GET_USERS_LIST_FAILURE:
      return {
        ...state,
        usersFetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case NOTIFICATION_TYPES.GET_NOTIFICATION_RECEIPIENTS_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case NOTIFICATION_TYPES.GET_NOTIFICATION_RECEIPIENTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        receipients: action.payload.data,
      };

    case NOTIFICATION_TYPES.GET_NOTIFICATION_RECEIPIENTS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case NOTIFICATION_TYPES.CREATE_NOTIFICATION_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case NOTIFICATION_TYPES.CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
        createNotfication: action.payload.data,
      };

    case NOTIFICATION_TYPES.CREATE_NOTIFICATION_FAILURE:
      return {
        ...state,
        submitting: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case NOTIFICATION_TYPES.CREATE_NOTIFICATION_CAMPAIGN_BEGIN:
      return {
        ...state,
        submittingCampaign: true,
        error: null,
      };

    case NOTIFICATION_TYPES.CREATE_NOTIFICATION_CAMPAIGN_SUCCESS:
      return {
        ...state,
        submittingCampaign: false,
        error: null,
        createNotfication: action.payload.data,
      };

    case NOTIFICATION_TYPES.CREATE_NOTIFICATION_CAMPAIGN_FAILURE:
      return {
        ...state,
        submittingCampaign: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case NOTIFICATION_TYPES.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };

    case NOTIFICATION_TYPES.RESET_FILTERS:
      return {
        ...state,
        filters: initialFilters.map((item) => {
          item.value = null;
          return item;
        }),
      };

    default:
      return state;
  }
}
