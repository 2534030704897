import SETTING_TYPES from "../types/settingTypes";
const initialFilters = Object.seal([
  {
    name: "distanceUnit",
    type: "select",
    placeHolder: "Select Distance Unit",
    options: [
      {
        value: "Miles",
        label: "Miles",
      },
      {
        value: "Kilometers",
        label: "Kilometers",
      },
    ],
    value: null,
  },
]);

const initialFiltersAvailableCountry = Object.seal([
  {
    name: "forIOS",
    type: "select",
    placeHolder: "Select IOS Availablity",
    options: [
      {
        value: 'true',
        label: "Available",
      },
      {
        value: 'false',
        label: "Not Available ",
      },
    ],
    value: null,
  },
  {
    name: "forAndroid",
    type: "select",
    placeHolder: "Select IOS Availablity",
    options: [
      {
        value: 'true',
        label: "Available",
      },
      {
        value: 'false',
        label: "Not Available ",
      },
    ],
    value: null,
  },
]);


const initialState = {
  fetching: false,
  fetchingDistance: false,
  fetchingDate: false,
  submitting: false,
  submittingThreshold: false,
  importing: false,
  exporting: false,
  error: null,
  countries: [],
  countriesData: [],
  countriesDistance: [],
  countriesDate: [],
  availCountries: [],
  states: [],
  cities: [],
  regions: [],
  tags: [],
  iOSPlans: [],
  androidPlans: [],
  filters: initialFilters,
  filtersAvailableCountry: initialFiltersAvailableCountry,
  options: {},
  data: null,
  exportData: null,
};

export default function settingReducer(state = initialState, action) {
  switch (action.type) {
    case SETTING_TYPES.RESET_STATE:
      return initialState;

    case SETTING_TYPES.GET_COUNTRY_LIST_BEGIN:
    case SETTING_TYPES.GET_BUCKET_DATA_BEGIN:
    case SETTING_TYPES.GET_STATE_LIST_BEGIN:
    case SETTING_TYPES.GET_CITY_LIST_BEGIN:
    case SETTING_TYPES.GET_REGION_LIST_BEGIN:
    case SETTING_TYPES.GET_TAG_LIST_BEGIN:
    case SETTING_TYPES.GET_PLAN_IOS_LIST_BEGIN:
    case SETTING_TYPES.GET_PLAN_ANDROID_LIST_BEGIN:
    case SETTING_TYPES.GET_AVAIL_COUNTRY_LIST_BEGIN:
    case SETTING_TYPES.TRIAL_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case SETTING_TYPES.GET_BUCKET_DISTANCE_LIST_BEGIN:
      return {
        ...state,
        fetchingDistance: true,
        error: null,
      };

    case SETTING_TYPES.GET_BUCKET_DATE_LIST_BEGIN:
      return {
        ...state,
        fetchingDate: true,
        error: null,
      };

    case SETTING_TYPES.GET_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        countries: action.payload.data,
      };
    case SETTING_TYPES.GET_BUCKET_DATA_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        countriesData: action.payload.data,
      };
    case SETTING_TYPES.GET_BUCKET_DISTANCE_LIST_SUCCESS:
      return {
        ...state,
        fetchingDistance: false,
        error: null,
        countriesDistance: action.payload.data,
      };
    case SETTING_TYPES.GET_BUCKET_DATE_LIST_SUCCESS:
      return {
        ...state,
        fetchingDate: false,
        error: null,
        countriesDate: action.payload.data,
      };

    case SETTING_TYPES.GET_STATE_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        states: action.payload.data,
      };

    case SETTING_TYPES.GET_CITY_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        cities: action.payload.data,
      };

    case SETTING_TYPES.GET_REGION_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        regions: action.payload.data,
      };

    case SETTING_TYPES.GET_TAG_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        tags: action.payload.data,
      };

    case SETTING_TYPES.GET_PLAN_IOS_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        iOSPlans: action.payload.data,
      };

    case SETTING_TYPES.GET_PLAN_ANDROID_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        androidPlans: action.payload.data,
      };

    case SETTING_TYPES.GET_AVAIL_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        availCountries: action.payload.data,
      };

    case SETTING_TYPES.TRIAL_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        data: action.payload.data,
      };

    case SETTING_TYPES.GET_COUNTRY_LIST_FAILURE:
    case SETTING_TYPES.GET_BUCKET_DATA_FAILURE:
    case SETTING_TYPES.GET_STATE_LIST_FAILURE:
    case SETTING_TYPES.GET_CITY_LIST_FAILURE:
    case SETTING_TYPES.GET_REGION_LIST_FAILURE:
    case SETTING_TYPES.GET_TAG_LIST_FAILURE:
    case SETTING_TYPES.GET_PLAN_IOS_LIST_FAILURE:
    case SETTING_TYPES.GET_PLAN_ANDROID_LIST_FAILURE:
    case SETTING_TYPES.GET_AVAIL_COUNTRY_LIST_FAILURE:
    case SETTING_TYPES.TRIAL_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case SETTING_TYPES.GET_BUCKET_DISTANCE_LIST_FAILURE:
      return {
        ...state,
        fetchingDistance: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };
    case SETTING_TYPES.GET_BUCKET_DATE_LIST_FAILURE:
      return {
        ...state,
        fetchingDate: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case SETTING_TYPES.ADD_COUNTRY_BEGIN:
    case SETTING_TYPES.ADD_STATE_BEGIN:
    case SETTING_TYPES.ADD_CITY_BEGIN:
    case SETTING_TYPES.ADD_REGION_BEGIN:
    case SETTING_TYPES.ADD_TAG_BEGIN:
    case SETTING_TYPES.ADD_PLAN_IOS_BEGIN:
    case SETTING_TYPES.ADD_PLAN_ANDROID_BEGIN:
    case SETTING_TYPES.ADD_AVAIL_COUNTRY_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case SETTING_TYPES.ADD_THRESHOLD_BEGIN:
      return {
        ...state,
        submittingThreshold: true,
        error: null,
      };

    case SETTING_TYPES.ADD_COUNTRY_SUCCESS:
    case SETTING_TYPES.ADD_STATE_SUCCESS:
    case SETTING_TYPES.ADD_CITY_SUCCESS:
    case SETTING_TYPES.ADD_REGION_SUCCESS:
    case SETTING_TYPES.ADD_TAG_SUCCESS:
    case SETTING_TYPES.ADD_PLAN_IOS_SUCCESS:
    case SETTING_TYPES.ADD_PLAN_ANDROID_SUCCESS:
    case SETTING_TYPES.ADD_AVAIL_COUNTRY_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
      };

    case SETTING_TYPES.ADD_THRESHOLD_SUCCESS:
      return {
        ...state,
        submittingThreshold: false,
        error: null,
      };

    case SETTING_TYPES.ADD_COUNTRY_FAILURE:
    case SETTING_TYPES.ADD_STATE_FAILURE:
    case SETTING_TYPES.ADD_CITY_FAILURE:
    case SETTING_TYPES.ADD_REGION_FAILURE:
    case SETTING_TYPES.ADD_TAG_FAILURE:
    case SETTING_TYPES.ADD_PLAN_IOS_FAILURE:
    case SETTING_TYPES.ADD_PLAN_ANDROID_FAILURE:
    case SETTING_TYPES.ADD_AVAIL_COUNTRY_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case SETTING_TYPES.ADD_THRESHOLD_FAILURE:
      return {
        ...state,
        submittingThreshold: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case SETTING_TYPES.UPDATE_COUNTRY_BEGIN:
    case SETTING_TYPES.UPDATE_STATE_BEGIN:
    case SETTING_TYPES.UPDATE_CITY_BEGIN:
    case SETTING_TYPES.UPDATE_REGION_BEGIN:
    case SETTING_TYPES.UPDATE_TAG_BEGIN:
    case SETTING_TYPES.UPDATE_PLAN_IOS_BEGIN:
    case SETTING_TYPES.UPDATE_PLAN_ANDROID_BEGIN:
    case SETTING_TYPES.UPDATE_AVAIL_COUNTRY_BEGIN:
    case SETTING_TYPES.UPDATE_BUCKET_LIST_BEGIN:
    case SETTING_TYPES.UPDATE_TRIAL_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case SETTING_TYPES.UPDATE_THRESHOLD_BEGIN:
      return {
        ...state,
        submittingThreshold: true,
        error: null,
      };

    case SETTING_TYPES.UPDATE_COUNTRY_SUCCESS:
    case SETTING_TYPES.UPDATE_STATE_SUCCESS:
    case SETTING_TYPES.UPDATE_CITY_SUCCESS:
    case SETTING_TYPES.UPDATE_REGION_SUCCESS:
    case SETTING_TYPES.UPDATE_TAG_SUCCESS:
    case SETTING_TYPES.UPDATE_PLAN_IOS_SUCCESS:
    case SETTING_TYPES.UPDATE_PLAN_ANDROID_SUCCESS:
    case SETTING_TYPES.UPDATE_AVAIL_COUNTRY_SUCCESS:
    case SETTING_TYPES.UPDATE_BUCKET_LIST_SUCCESS:
    case SETTING_TYPES.UPDATE_TRIAL_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
      };

    case SETTING_TYPES.UPDATE_THRESHOLD_SUCCESS:
      return {
        ...state,
        submittingThreshold: false,
        error: null,
      };

    case SETTING_TYPES.UPDATE_COUNTRY_FAILURE:
    case SETTING_TYPES.UPDATE_STATE_FAILURE:
    case SETTING_TYPES.UPDATE_CITY_FAILURE:
    case SETTING_TYPES.UPDATE_REGION_FAILURE:
    case SETTING_TYPES.UPDATE_TAG_FAILURE:
    case SETTING_TYPES.UPDATE_PLAN_IOS_FAILURE:
    case SETTING_TYPES.UPDATE_PLAN_ANDROID_FAILURE:
    case SETTING_TYPES.UPDATE_AVAIL_COUNTRY_FAILURE:
    case SETTING_TYPES.UPDATE_BUCKET_LIST_FAILURE:
    case SETTING_TYPES.UPDATE_TRIAL_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case SETTING_TYPES.UPDATE_THRESHOLD_FAILURE:
      return {
        ...state,
        submittingThreshold: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case SETTING_TYPES.DELETE_COUNTRY_BEGIN:
    case SETTING_TYPES.DELETE_STATE_BEGIN:
    case SETTING_TYPES.DELETE_CITY_BEGIN:
    case SETTING_TYPES.DELETE_REGION_BEGIN:
    case SETTING_TYPES.DELETE_TAG_BEGIN:
    case SETTING_TYPES.DELETE_PLAN_IOS_BEGIN:
    case SETTING_TYPES.DELETE_PLAN_ANDROID_BEGIN:
    case SETTING_TYPES.DELETE_AVAIL_COUNTRY_BEGIN:
      return {
        ...state,
        submitting: true,
        error: null,
      };

    case SETTING_TYPES.DELETE_THRESHOLD_BEGIN:
      return {
        ...state,
        submittingThreshold: true,
        error: null,
      };

    case SETTING_TYPES.DELETE_COUNTRY_SUCCESS:
    case SETTING_TYPES.DELETE_STATE_SUCCESS:
    case SETTING_TYPES.DELETE_CITY_SUCCESS:
    case SETTING_TYPES.DELETE_REGION_SUCCESS:
    case SETTING_TYPES.DELETE_TAG_SUCCESS:
    case SETTING_TYPES.DELETE_PLAN_IOS_SUCCESS:
    case SETTING_TYPES.DELETE_PLAN_ANDROID_SUCCESS:
    case SETTING_TYPES.DELETE_AVAIL_COUNTRY_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: null,
      };

    case SETTING_TYPES.DELETE_THRESHOLD_SUCCESS:
      return {
        ...state,
        submittingThreshold: false,
        error: null,
      };

    case SETTING_TYPES.DELETE_COUNTRY_FAILURE:
    case SETTING_TYPES.DELETE_STATE_FAILURE:
    case SETTING_TYPES.DELETE_CITY_FAILURE:
    case SETTING_TYPES.DELETE_REGION_FAILURE:
    case SETTING_TYPES.DELETE_TAG_FAILURE:
    case SETTING_TYPES.DELETE_PLAN_IOS_FAILURE:
    case SETTING_TYPES.DELETE_PLAN_ANDROID_FAILURE:
    case SETTING_TYPES.DELETE_AVAIL_COUNTRY_FAILURE:
      return {
        ...state,
        submitting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case SETTING_TYPES.DELETE_THRESHOLD_FAILURE:
      return {
        ...state,
        submittingThreshold: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case SETTING_TYPES.IMPORT_COUNTRY_LIST_BEGIN:
    case SETTING_TYPES.IMPORT_STATE_LIST_BEGIN:
    case SETTING_TYPES.IMPORT_CITY_LIST_BEGIN:
    case SETTING_TYPES.IMPORT_FORMAT_LIST_BEGIN:
    case SETTING_TYPES.IMPORT_COUNTRY_THRESHOLD_LIST_BEGIN:
    case SETTING_TYPES.IMPORT_STATE_THRESHOLD_LIST_BEGIN:
    case SETTING_TYPES.IMPORT_CITY_THRESHOLD_LIST_BEGIN:
      return {
        ...state,
        importing: true,
        error: null,
      };

    case SETTING_TYPES.IMPORT_COUNTRY_LIST_SUCCESS:
    case SETTING_TYPES.IMPORT_STATE_LIST_SUCCESS:
    case SETTING_TYPES.IMPORT_CITY_LIST_SUCCESS:
    case SETTING_TYPES.IMPORT_FORMAT_LIST_SUCCESS:
    case SETTING_TYPES.IMPORT_COUNTRY_THRESHOLD_LIST_SUCCESS:
    case SETTING_TYPES.IMPORT_STATE_THRESHOLD_LIST_SUCCESS:
    case SETTING_TYPES.IMPORT_CITY_THRESHOLD_LIST_SUCCESS:
      return {
        ...state,
        importing: false,
        error: null,
      };

    case SETTING_TYPES.IMPORT_COUNTRY_LIST_FAILURE:
    case SETTING_TYPES.IMPORT_STATE_LIST_FAILURE:
    case SETTING_TYPES.IMPORT_CITY_LIST_FAILURE:
    case SETTING_TYPES.IMPORT_FORMAT_LIST_FAILURE:
    case SETTING_TYPES.IMPORT_COUNTRY_THRESHOLD_LIST_FAILURE:
    case SETTING_TYPES.IMPORT_STATE_THRESHOLD_LIST_FAILURE:
    case SETTING_TYPES.IMPORT_CITY_THRESHOLD_LIST_FAILURE:
      return {
        ...state,
        importing: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };
    //
    case SETTING_TYPES.EXPORT_COUNTRY_LIST_BEGIN:
    case SETTING_TYPES.EXPORT_STATE_LIST_BEGIN:
    case SETTING_TYPES.EXPORT_CITY_LIST_BEGIN:
    case SETTING_TYPES.EXPORT_FORMAT_LIST_BEGIN:
    case SETTING_TYPES.EXPORT_COUNTRY_THRESHOLD_LIST_BEGIN:
    case SETTING_TYPES.EXPORT_STATE_THRESHOLD_LIST_BEGIN:
    case SETTING_TYPES.EXPORT_CITY_THRESHOLD_LIST_BEGIN:
      return {
        ...state,
        exporting: true,
        error: null,
      };

    case SETTING_TYPES.EXPORT_COUNTRY_LIST_SUCCESS:
    case SETTING_TYPES.EXPORT_STATE_LIST_SUCCESS:
    case SETTING_TYPES.EXPORT_CITY_LIST_SUCCESS:
    case SETTING_TYPES.EXPORT_FORMAT_LIST_SUCCESS:
    case SETTING_TYPES.EXPORT_COUNTRY_THRESHOLD_LIST_SUCCESS:
    case SETTING_TYPES.EXPORT_STATE_THRESHOLD_LIST_SUCCESS:
    case SETTING_TYPES.EXPORT_CITY_THRESHOLD_LIST_SUCCESS:
      return {
        ...state,
        exporting: false,
        exportData: action.payload.data,
        error: null,
      };

    case SETTING_TYPES.EXPORT_COUNTRY_LIST_FAILURE:
    case SETTING_TYPES.EXPORT_STATE_LIST_FAILURE:
    case SETTING_TYPES.EXPORT_CITY_LIST_FAILURE:
    case SETTING_TYPES.EXPORT_FORMAT_LIST_FAILURE:
    case SETTING_TYPES.EXPORT_COUNTRY_THRESHOLD_LIST_FAILURE:
    case SETTING_TYPES.EXPORT_STATE_THRESHOLD_LIST_FAILURE:
    case SETTING_TYPES.EXPORT_CITY_THRESHOLD_LIST_FAILURE:
      return {
        ...state,
        exporting: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case SETTING_TYPES.RESET_EXPORT_DATA:
      return {
        ...state,
        exportData: action.payload,
        exporting: false,
      };
    case SETTING_TYPES.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };

    case SETTING_TYPES.RESET_FILTERS:
      return {
        ...state,
        filters: initialFilters.map((item) => {
          item.value = null;
          return item;
        }),
      };

    case SETTING_TYPES.SET_FILTERS_AVAIL_COUNTRY:
      return {
        ...state,
        filters: action.payload,
      };

    case SETTING_TYPES.RESET_FILTERS_AVAIL_COUNTRY:
      return {
        ...state,
        filters: initialFiltersAvailableCountry.map((item) => {
          item.value = null;
          return item;
        }),
      };
    default:
      return state;
  }
}
