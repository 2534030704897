const AUTH_TYPES = {
  RESET_STATE: 'RESET_STATE',

  UPDATE_USER_DATA: 'UPDATE_USER_DATA',

  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_NO_2FA_SUCCESS: 'LOGIN_NO_2FA_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  RESEND_LOGIN_OTP_BEGIN: 'RESEND_LOGIN_OTP_BEGIN',
  RESEND_LOGIN_OTP_SUCCESS: 'RESEND_LOGIN_OTP_SUCCESS',
  RESEND_LOGIN_OTP_FAILURE: 'RESEND_LOGIN_OTP_FAILURE',

  VERIFY_LOGIN_OTP_BEGIN: 'VERIFY_LOGIN_OTP_BEGIN',
  VERIFY_LOGIN_OTP_SUCCESS: 'VERIFY_LOGIN_OTP_SUCCESS',
  VERIFY_LOGIN_OTP_FAILURE: 'VERIFY_LOGIN_OTP_FAILURE',

  REFRESH_TOKEN_BEGIN: 'REFRESH_TOKEN_BEGIN',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAILURE: 'REFRESH_TOKEN_FAILURE',

  CHANGE_PASSWORD_BEGIN: 'CHANGE_PASSWORD_BEGIN',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

  VERIFY_ADMIN_SESSION_BEGIN: 'VERIFY_ADMIN_SESSION_BEGIN',
  VERIFY_ADMIN_SESSION_SUCCESS: 'VERIFY_ADMIN_SESSION_SUCCESS',
  VERIFY_ADMIN_SESSION_FAILURE: 'VERIFY_ADMIN_SESSION_FAILURE',

  VERIFY_ADMIN_SESSION_OTP_BEGIN: 'VERIFY_ADMIN_SESSION_OTP_BEGIN',
  VERIFY_ADMIN_SESSION_OTP_SUCCESS: 'VERIFY_ADMIN_SESSION_OTP_SUCCESS',
  VERIFY_ADMIN_SESSION_OTP_FAILURE: 'VERIFY_ADMIN_SESSION_OTP_FAILURE',

  SET_SUBMITTING: 'SET_SUBMITTING',
  SET_IDLE_TIMEOUT: 'SET_SESSION_IDLE_TIMEOUT',

  LOGOUT: 'LOGOUT',

  GET_PROFILE_DETAILS_BEGIN: 'GET_PROFILE_DETAILS_BEGIN',
  GET_PROFILE_DETAILS_SUCCESS: 'GET_PROFILE_DETAILS_SUCCESS',
  GET_PROFILE_DETAILS_FAILURE: 'GET_PROFILE_DETAILS_FAILURE',
};

export default AUTH_TYPES;
