import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Nav, NavItem } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import Constants from "../../utils/constants";
import { validateRoutesAccess } from "../../utils";

const routes = [
  {
    key: Constants.ROLE_ATTRIBUTES.DASHBOARD,
    validateRole: true,
    route: "/dashboard",
    name: "Dashboard",
  },
  { key: Constants.ROLE_ATTRIBUTES.USER, validateRole: true, route: "/users", name: "Users" },
  { key: Constants.ROLE_ATTRIBUTES.GOAL, validateRole: true, route: "/goals", name: "Goals" },
  { key: Constants.ROLE_ATTRIBUTES.TEAM, validateRole: true, route: "/team", name: "Team" },
  { key: Constants.ROLE_ATTRIBUTES.ROLE, validateRole: true, route: "/roles", name: "Roles" },
  {
    key: Constants.ROLE_ATTRIBUTES.SETTING,
    validateRole: true,
    route: "/settings",
    name: "Settings",
  },
  {
    key: Constants.ROLE_ATTRIBUTES.NOTIFICATION,
    validateRole: false,
    route: "/notifications",
    name: "Notifications",
  },
];

const Sidebar = ({ loggedInUser }) => {
  const [allowedRoutes, setAllowedRoutes] = useState([]);

  useEffect(() => {
    const { children } = validateRoutesAccess({ children: routes }, loggedInUser ? loggedInUser.role : undefined);

    const allowedMenus = [];
    children.forEach((childRoute) => childRoute.isValidRole && allowedMenus.push(childRoute));

    setAllowedRoutes(allowedMenus);
  }, [loggedInUser]);

  return (
    <aside className="pe-3">
      <PerfectScrollbar component="div" className="pt-4 pb-5">
        <Nav className="flex-column pe-4">
          {allowedRoutes.map(({ route, name }, index) => (
            <NavItem key={index} as={NavLink} to={route} className="ps-4">
              {name}
            </NavItem>
          ))}
        </Nav>
      </PerfectScrollbar>
    </aside>
  );
};

const mapStateToProps = (state) => ({
  loggedInUser: state.auth.profileData,
});

export default connect(mapStateToProps, null)(Sidebar);
