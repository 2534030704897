import React from 'react';
import { get } from 'lodash-es';
import paths from 'path';

import { hierarchize } from './utils/hierarchical';

const keyName = 'key';
const pathName = 'path';
const uniqueKeyName = 'uniqueKey';

function pathGenerator(node, parent) {
  const parentUniqueKey = get(parent, uniqueKeyName);
  const uniqueKey = parentUniqueKey ? parentUniqueKey + '.' + node[keyName] : node[keyName];

  const parentPath = get(parent, pathName, '');
  const path = get(node, pathName, paths.join(parentPath, node[keyName]));
  node[uniqueKeyName] = uniqueKey;
  node[pathName] = path;
}

const routeConfig = hierarchize(
  {
    key: 'dashboard',
    name: 'Dashboard',
    path: '/',
    exact: true,
    component: React.lazy(() => import('./containers/Dashboard')),
    children: [
      {
        key: 'login',
        name: 'Login',
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() => import('./containers/Auth/Login/index')),
        validateRole: false,
      },
      {
        key: 'verify-passcode',
        name: 'Verify Login Passcode',
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() => import('./containers/Auth/VerifyLoginOTP/index')),
        validateRole: false,
      },
      {
        key: 'forgot-password',
        name: 'Forgot Password',
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() => import('./containers/Auth/ForgotPassword')),
        validateRole: false,
      },
      {
        key: 'reset-password/:resetPasswordToken',
        name: 'Reset Password',
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() => import('./containers/Auth/ResetPassword')),
        validateRole: false,
      },
      {
        key: 'invite/:inviteToken',
        name: 'Invite',
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() => import('./containers/Auth/Invite')),
        validateRole: false,
      },
      {
        key: 'dashboard',
        name: 'Dashboard',
        exact: true,
        component: React.lazy(() => import('./containers/Dashboard')),
        validateRole: true,
      },
      {
        key: 'account',
        name: 'Account',
        isHidden: true,
        component: React.lazy(() => import('./containers/Account')),
        validateRole: false,
      },
      {
        key: 'users',
        name: 'Users',
        component: React.lazy(() => import('./containers/Users')),
        validateRole: true,
      },
      {
        key: 'user/:id',
        name: 'User Details',
        component: React.lazy(() => import('./containers/UserDetails')),
        validateRole: true,
      },
      {
        key: 'goals',
        name: 'Goals',
        component: React.lazy(() => import('./containers/Goals')),
        validateRole: true,
      },
      {
        key: 'team',
        name: 'Team',
        component: React.lazy(() => import('./containers/Team')),
        validateRole: true,
      },
      {
        key: 'roles',
        name: 'Roles',
        component: React.lazy(() => import('./containers/Roles')),
        validateRole: true,
      },
      {
        key: 'settings',
        name: 'Settings',
        component: React.lazy(() => import('./containers/Settings')),
        validateRole: true,
      },      
      {
        key: 'notifications',
        name: 'Notifications',
        component: React.lazy(() => import('./containers/Notifications')),
        // TODO: fix this 
        validateRole: false,
      },
      {
        key: 'cron-jobs',
        name: 'Manage Cron Jobs',
        component: React.lazy(() => import('./containers/CronJobs')),
        validateRole: false,
      },
    ],
  },
  null,
  pathGenerator
);

export default routeConfig;
