const USER_TYPES = {
  RESET_STATE: 'RESET_STATE',

  GET_LIST_BEGIN: 'GET_USER_LIST_BEGIN',
  GET_LIST_SUCCESS: 'GET_USER_LIST_SUCCESS',
  GET_LIST_FAILURE: 'GET_USER_LIST_FAILURE',

  GET_DETAILS_BEGIN: 'GET_USER_DETAILS_BEGIN',
  GET_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
  GET_DETAILS_FAILURE: 'GET_USER_DETAILS_FAILURE',

  GET_USER_GOALS_BEGIN: 'GET_USER_GOALS_BEGIN',
  GET_USER_GOALS_SUCCESS: 'GET_USER_GOALS_SUCCESS',
  GET_USER_GOALS_FAILURE: 'GET_USER_GOALS_FAILURE',

  CREATE_USER_REPORT_BEGIN: 'CREATE_USER_REPORT_BEGIN',
  CREATE_USER_REPORT_SUCCESS: 'CREATE_USER_REPORT_SUCCESS',
  CREATE_USER_REPORT_FAILURE: 'CREATE_USER_REPORT_FAILURE',

  CREATE_USER_GOAL_REPORT_BEGIN: 'CREATE_USER_GOAL_REPORT_BEGIN',
  CREATE_USER_GOAL_REPORT_SUCCESS: 'CREATE_USER_GOAL_REPORT_SUCCESS',
  CREATE_USER_GOAL_REPORT_FAILURE: 'CREATE_USER_GOAL_REPORT_FAILURE',

  GET_USER_TIMELINE_BEGIN: 'GET_USER_TIMELINE_BEGIN',
  GET_USER_TIMELINE_SUCCESS: 'GET_USER_TIMELINE_SUCCESS',
  GET_USER_TIMELINE_FAILURE: 'GET_USER_TIMELINE_FAILURE',

  UPDATE_USER_BEGIN: 'UPDATE_USER_BEGIN',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

  DELETE_USER_BEGIN: 'DELETE_USER_BEGIN',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

  GET_GENERATED_REPORTS_LIST_BEGIN: 'GET_GENERATED_REPORTS_LIST_BEGIN',
  GET_GENERATED_REPORTS_LIST_SUCCESS: 'GET_GENERATED_REPORTS_LIST_SUCCESS',
  GET_GENERATED_REPORTS_LIST_FAILURE: 'GET_GENERATED_REPORTS_LIST_FAILURE',

  GET_REPORTS_SIGNED_URL_BEGIN: 'GET_REPORTS_SIGNED_URL_BEGIN',
  GET_REPORTS_SIGNED_URL_SUCCESS: 'GET_REPORTS_SIGNED_URL_SUCCESS',
  GET_REPORTS_SIGNED_URL_FAILURE: 'GET_REPORTS_SIGNED_URL_FAILURE',

  SET_FILTERS: 'SET_FILTERS',
  RESET_FILTERS: 'RESET_FILTERS',
};

export default USER_TYPES;
