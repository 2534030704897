import React, { useState } from 'react';
import { Modal, Button, Spinner, Form, Row, Col } from 'react-bootstrap';

import PropTypes from 'prop-types';
import Input from '../Form/Input';
import { useForm } from 'react-hook-form';
import { verifyAdminSession, verifyAdminSessionOtp } from '../../services/validations';

const SessionIdleTimeout = ({ handleVerifySession, handleVerifySessionOtp, handleLogout, verifyPassword }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: verifyAdminSession,
    defaultValues: {
      password: '',
    },
  });

  const {
    register: register1,
    handleSubmit: handleSubmitOtp,
    formState: { errors: errors1 },
  } = useForm({
    resolver: verifyAdminSessionOtp,
    defaultValues: {
      passcode: '',
    },
  });

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async (data) => {
    setSubmitting(true);
    await handleVerifySession(data);
    setSubmitting(false);
  };

  const onSubmitOtp = async (data) => {
    setSubmitting(true);
    await handleVerifySessionOtp(data);
    setSubmitting(false);
  };

  return (
    <Modal show={true} backdrop="static" keyboard={false} centered={true}>
      <Modal.Header>
        <Modal.Title>Session Idle Timeout</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {verifyPassword ? (
          <Form onSubmit={handleSubmitOtp(onSubmitOtp)}>
            <Row>
              <Col xs={12}>
                <Input
                  id="passcode"
                  type="number"
                  name="passcode"
                  label="Passcode"
                  {...register1('passcode')}
                  error={errors1.passcode?.message}
                />
              </Col>

              <Col xs={12} className="pt-4 position-relative">
                <Button type="submit" variant="primary" size="md" className={`w-100`} disabled={submitting}>
                  {!submitting && <span>Verify Passcode</span>}
                  {submitting && <Spinner animation="border" />}
                </Button>

                <div className="d-flex justify-content-center mt-3">
                  <Button
                    type="button"
                    variant="outline-secondary"
                    size="md"
                    className={`w-100`}
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs={12}>
                <Input
                  id="password"
                  type="password"
                  name="password"
                  label="Password"
                  {...register('password')}
                  error={errors.password?.message}
                />
              </Col>

              <Col xs={12} className="pt-4 position-relative">
                <Button type="submit" variant="primary" size="md" className={`w-100`} disabled={submitting}>
                  {!submitting && <span>Verify Password</span>}
                  {submitting && <Spinner animation="border" />}
                </Button>

                <div className="d-flex justify-content-center mt-3">
                  <Button
                    type="button"
                    variant="outline-secondary"
                    size="md"
                    className={`w-100`}
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

SessionIdleTimeout.propTypes = {
  handleVerifySession: PropTypes.func,
  handleVerifySessionOtp: PropTypes.func,
  handleLogout: PropTypes.func,
};

SessionIdleTimeout.defaultProps = {
  handleVerifySession: () => null,
  handleVerifySessionOtp: () => null,
  handleLogout: () => null,
};

export default SessionIdleTimeout;
