const NOTIFICATION_TYPES = {
  GET_NOTIFICATION_LIST_BEGIN: "GET_NOTIFICATION_LIST_BEGIN",
  GET_NOTIFICATION_LIST_SUCCESS: "GET_NOTIFICATION_LIST_SUCCESS",
  GET_NOTIFICATION_LIST_FAILURE: "GET_NOTIFICATION_LIST_FAILURE",

  GET_USERS_LIST_BEGIN: "GET_USERS_LIST_BEGIN",
  GET_USERS_LIST_SUCCESS: "GET_USERS_LIST_SUCCESS",
  GET_USERS_LIST_FAILURE: "GET_USERS_LIST_FAILURE",

  GET_NOTIFICATION_RECEIPIENTS_BEGIN: "GET_NOTIFICATION_RECEIPIENTS_BEGIN",
  GET_NOTIFICATION_RECEIPIENTS_SUCCESS: "GET_NOTIFICATION_RECEIPIENTS_SUCCESS",
  GET_NOTIFICATION_RECEIPIENTS_FAILURE: "GET_NOTIFICATION_RECEIPIENTS_FAILURE",

  CREATE_NOTIFICATION_BEGIN: "CREATE_NOTIFICATION_BEGIN",
  CREATE_NOTIFICATION_SUCCESS: "CREATE_NOTIFICATION_SUCCESS",
  CREATE_NOTIFICATION_FAILURE: "CREATE_NOTIFICATION_FAILURE",

  CREATE_NOTIFICATION_CAMPAIGN_BEGIN: "CREATE_NOTIFICATION_CAMPAIGN_BEGIN",
  CREATE_NOTIFICATION_CAMPAIGN_SUCCESS: "CREATE_NOTIFICATION_CAMPAIGN_SUCCESS",
  CREATE_NOTIFICATION_CAMPAIGN_FAILURE: "CREATE_NOTIFICATION_CAMPAIGN_FAILURE",

  DAILY_REMINDER_NOTIFICATION_SETTING_BEGIN: "DAILY_REMINDER_NOTIFICATION_SETTING_BEGIN",
  DAILY_REMINDER_NOTIFICATION_SETTING_SUCCESS: "DAILY_REMINDER_NOTIFICATION_SETTING_SUCCESS",
  DAILY_REMINDER_NOTIFICATION_SETTING_FAILURE: "DAILY_REMINDER_NOTIFICATION_SETTING_FAILURE",

  DAILY_REMINDER_NOTIFICATION_SETTING_LIST_BEGIN: "DAILY_REMINDER_NOTIFICATION_SETTING_LIST_BEGIN",
  DAILY_REMINDER_NOTIFICATION_SETTING_LIST_SUCCESS: "DAILY_REMINDER_NOTIFICATION_SETTING_LIST_SUCCESS",
  DAILY_REMINDER_NOTIFICATION_SETTING_LIST_FAILURE: "DAILY_REMINDER_NOTIFICATION_SETTING_LIST_FAILURE",
  
  SET_FILTERS: "SET_FILTERS",
  RESET_FILTERS: "RESET_FILTERS",
};

export default NOTIFICATION_TYPES;
