import STAT_TYPES from '../types/statTypes';

const initialState = {
  goalTypeFetching: false,
  goalCategoryFetching: false,
  goalStatusFetching: false,
  userActivityFetching: false,
  userProfessionFetching: false,
  userNotificationFetching: false,
  userAnalysisFetching: false,
  userTravelFetching: false,
  userBiometricFetching: false,
  userGrowthCountryFetching: false,
  userLocationFetching: false,
  userGoalCountriesFetching: false,
  userGoalStatesFetching: false,
  userGoalCitiesFetching: false,
  activeUserFetching: false,
  userOSFetching: false,
  userPresenceOSFetching: false,
  // Conversion
  userGrowthByConversion: false,
  convertedUserByGoalCount: false,
  convertedUserByGoalType: false,
  convertedUserByOS: false,

  error: null,
  goalTypesData: null,
  goalCategoriesData: null,
  goalStatusData: null,
  userActivityData: null,
  userProfessionData: null,
  userNotificationData: null,
  userAnalysisData: null,
  userTravelData: null,
  userBiometricData: null,
  userGrowthCountryData: null,
  userLocationData: null,
  options: {},
  goalCountries: null,
  goalStates: null,
  goalCities: null,
  activeUser: null,
  userOS: null,
  userPresenceOSData: null,
  userGrowthByConversionData: null,
  convertedUserByGoalCountDaa: null,
  convertedUserByGoalTypeData: null,
  convertedUserByOSData: null,
};

export default function statReducer(state = initialState, action) {
  switch (action.type) {
    case STAT_TYPES.RESET_STATE:
      return initialState;

    case STAT_TYPES.GET_USER_GOAL_BY_COUNTRY_BEGIN:
      return {
        ...state,
        userGoalCountriesFetching: true,
        error: null,
      };

    case STAT_TYPES.GET_USER_GOAL_BY_COUNTRY_SUCCESS:
      return {
        ...state,
        userGoalCountriesFetching: false,
        error: null,
        goalCountries: action.payload.data,
      };

    case STAT_TYPES.GET_USER_GOAL_BY_COUNTRY_FAILURE:
      return {
        ...state,
        userGoalCountriesFetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case STAT_TYPES.GET_USER_GOAL_BY_STATE_BEGIN:
      return {
        ...state,
        userGoalStatesFetching: true,
        error: null,
      };

    case STAT_TYPES.GET_USER_GOAL_BY_STATE_SUCCESS:
      return {
        ...state,
        userGoalStatesFetching: false,
        error: null,
        goalStates: action.payload.data,
      };

    case STAT_TYPES.GET_USER_GOAL_BY_STATE_FAILURE:
      return {
        ...state,
        userGoalStatesFetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case STAT_TYPES.GET_USER_GOAL_BY_CITY_BEGIN:
      return {
        ...state,
        userGoalCitiesFetching: true,
        error: null,
      };

    case STAT_TYPES.GET_USER_GOAL_BY_CITY_SUCCESS:
      return {
        ...state,
        userGoalCitiesFetching: false,
        error: null,
        goalCities: action.payload.data,
      };

    case STAT_TYPES.GET_USER_GOAL_BY_CITY_FAILURE:
      return {
        ...state,
        userGoalCitiesFetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case STAT_TYPES.GET_GOAL_TYPE_BEGIN:
      return {
        ...state,
        goalTypeFetching: true,
        error: null,
      };

    case STAT_TYPES.GET_GOAL_TYPE_SUCCESS:
      return {
        ...state,
        goalTypeFetching: false,
        error: null,
        goalTypesData: action.payload.data,
      };

    case STAT_TYPES.GET_GOAL_TYPE_FAILURE:
      return {
        ...state,
        goalTypeFetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case STAT_TYPES.GET_GOAL_CATEGORY_BEGIN:
      return {
        ...state,
        goalCategoryFetching: true,
        error: null,
      };

    case STAT_TYPES.GET_GOAL_CATEGORY_SUCCESS:
      return {
        ...state,
        goalCategoryFetching: false,
        error: null,
        goalCategoriesData: action.payload.data,
      };

    case STAT_TYPES.GET_GOAL_CATEGORY_FAILURE:
      return {
        ...state,
        goalCategoryFetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case STAT_TYPES.GET_GOAL_STATUS_BEGIN:
      return {
        ...state,
        goalStatusFetching: true,
        error: null,
      };

    case STAT_TYPES.GET_GOAL_STATUS_SUCCESS:
      return {
        ...state,
        goalStatusFetching: false,
        error: null,
        goalStatusData: action.payload.data,
      };

    case STAT_TYPES.GET_GOAL_STATUS_FAILURE:
      return {
        ...state,
        goalStatusFetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case STAT_TYPES.GET_USER_ACTIVITY_BEGIN:
      return {
        ...state,
        userActivityFetching: true,
        error: null,
      };

    case STAT_TYPES.GET_USER_ACTIVITY_SUCCESS:
      return {
        ...state,
        userActivityFetching: false,
        error: null,
        userActivityData: action.payload.data,
      };

    case STAT_TYPES.GET_USER_ACTIVITY_FAILURE:
      return {
        ...state,
        userActivityFetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case STAT_TYPES.GET_USER_PROFESSION_BEGIN:
      return {
        ...state,
        userProfessionFetching: true,
        error: null,
      };

    case STAT_TYPES.GET_USER_PROFESSION_SUCCESS:
      return {
        ...state,
        userProfessionFetching: false,
        error: null,
        userProfessionData: action.payload.data,
      };

    case STAT_TYPES.GET_USER_PROFESSION_FAILURE:
      return {
        ...state,
        userProfessionFetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case STAT_TYPES.GET_USER_NOTIFICATION_BEGIN:
      return {
        ...state,
        userNotificationFetching: true,
        error: null,
      };

    case STAT_TYPES.GET_USER_NOTIFICATION_SUCCESS:
      return {
        ...state,
        userNotificationFetching: false,
        error: null,
        userNotificationData: action.payload.data[0],
      };

    case STAT_TYPES.GET_USER_NOTIFICATION_FAILURE:
      return {
        ...state,
        userNotificationFetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case STAT_TYPES.GET_USER_TRAVEL_BEGIN:
      return {
        ...state,
        userTravelFetching: true,
        error: null,
      };

    case STAT_TYPES.GET_USER_TRAVEL_SUCCESS:
      return {
        ...state,
        userTravelFetching: false,
        error: null,
        userTravelData: action.payload.data,
      };

    case STAT_TYPES.GET_USER_TRAVEL_FAILURE:
      return {
        ...state,
        userTravelFetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case STAT_TYPES.GET_USER_BIOMETRIC_BEGIN:
      return {
        ...state,
        userBiometricFetching: true,
        error: null,
      };

    case STAT_TYPES.GET_USER_BIOMETRIC_SUCCESS:
      return {
        ...state,
        userBiometricFetching: false,
        error: null,
        userBiometricData: action.payload.data,
      };

    case STAT_TYPES.GET_USER_BIOMETRIC_FAILURE:
      return {
        ...state,
        userBiometricFetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case STAT_TYPES.GET_USER_ANALYSIS_BEGIN:
      return {
        ...state,
        userAnalysisFetching: true,
        error: null,
      };

    case STAT_TYPES.GET_USER_ANALYSIS_SUCCESS:
      return {
        ...state,
        userAnalysisFetching: false,
        error: null,
        userAnalysisData: action.payload.data,
      };

    case STAT_TYPES.GET_USER_ANALYSIS_FAILURE:
      return {
        ...state,
        userAnalysisFetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case STAT_TYPES.GET_USER_GROWTH_COUNTRY_BEGIN:
      return {
        ...state,
        userGrowthCountryFetching: true,
        error: null,
      };

    case STAT_TYPES.GET_USER_GROWTH_COUNTRY_SUCCESS:
      return {
        ...state,
        userGrowthCountryFetching: false,
        error: null,
        userGrowthCountryData: action.payload.data,
      };

    case STAT_TYPES.GET_USER_GROWTH_COUNTRY_FAILURE:
      return {
        ...state,
        userGrowthCountryFetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case STAT_TYPES.GET_USER_LOCATION_BEGIN:
      return {
        ...state,
        userLocationFetching: true,
        error: null,
      };

    case STAT_TYPES.GET_USER_LOCATION_SUCCESS:
      return {
        ...state,
        userLocationFetching: false,
        error: null,
        userLocationData: action.payload.data,
      };

    case STAT_TYPES.GET_USER_LOCATION_FAILURE:
      return {
        ...state,
        userLocationFetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case STAT_TYPES.GET_ACTIVE_USER_FREQUENCY_BEGIN:
      return {
        ...state,
        activeUserFetching: true,
        error: null,
      };

    case STAT_TYPES.GET_ACTIVE_USER_FREQUENCY_SUCCESS:
      return {
        ...state,
        activeUserFetching: false,
        error: null,
        activeUser: action.payload.data,
      };

    case STAT_TYPES.GET_ACTIVE_USER_FREQUENCY_FAILURE:
      return {
        ...state,
        activeUserFetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case STAT_TYPES.GET_USER_GROWTH_OS_BEGIN:
      return {
        ...state,
        userOSFetching: true,
        error: null,
      };

    case STAT_TYPES.GET_USER_GROWTH_OS_SUCCESS:
      return {
        ...state,
        userOSFetching: false,
        error: null,
        userOS: action.payload.data,
      };

    case STAT_TYPES.GET_USER_GROWTH_OS_FAILURE:
      return {
        ...state,
        userOSFetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case STAT_TYPES.GET_USER_PRESENCE_OS_BEGIN:
      return {
        ...state,
        userPresenceOSFetching: true,
        error: null,
      };

    case STAT_TYPES.GET_USER_PRESENCE_OS_SUCCESS:
      return {
        ...state,
        userPresenceOSFetching: false,
        error: null,
        userPresenceOSData: action.payload.data,
      };

    case STAT_TYPES.GET_USER_PRESENCE_OS_FAILURE:
      return {
        ...state,
        userPresenceOSFetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case STAT_TYPES.GET_USER_GROWTH_CONVERSION_BEGIN:
      return {
        ...state,
        userGrowthByConversion: true,
        error: null,
      };

    case STAT_TYPES.GET_USER_GROWTH_CONVERSION_SUCCESS:
      return {
        ...state,
        userGrowthByConversion: false,
        error: null,
        userGrowthByConversionData: action.payload.data,
      };

    case STAT_TYPES.GET_USER_GROWTH_CONVERSION_FAILURE:
      return {
        ...state,
        userGrowthByConversion: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };
    case STAT_TYPES.GET_CONVERTED_USER_BY_GOAL_COUNT_BEGIN:
      return {
        ...state,
        convertedUserByGoalCount: true,
        error: null,
      };

    case STAT_TYPES.GET_CONVERTED_USER_BY_GOAL_COUNT_SUCCESS:
      return {
        ...state,
        convertedUserByGoalCount: false,
        error: null,
        convertedUserByGoalCountData: action.payload.data,
      };

    case STAT_TYPES.GET_CONVERTED_USER_BY_GOAL_COUNT_FAILURE:
      return {
        ...state,
        convertedUserByGoalCount: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };

    case STAT_TYPES.GET_CONVERTED_USER_BY_GOAL_TYPE_BEGIN:
      return {
        ...state,
        convertedUserByGoalType: true,
        error: null,
      };

    case STAT_TYPES.GET_CONVERTED_USER_BY_GOAL_TYPE_SUCCESS:
      return {
        ...state,
        convertedUserByGoalType: false,
        error: null,
        convertedUserByGoalTypeData: action.payload.data,
      };

    case STAT_TYPES.GET_CONVERTED_USER_BY_GOAL_TYPE_FAILURE:
      return {
        ...state,
        convertedUserByGoalType: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };
    case STAT_TYPES.GET_CONVERTED_USER_BY_OS_BEGIN:
      return {
        ...state,
        convertedUserByOS: true,
        error: null,
      };

    case STAT_TYPES.GET_CONVERTED_USER_BY_OS_SUCCESS:
      return {
        ...state,
        convertedUserByOS: false,
        error: null,
        convertedUserByOSData: action.payload.data,
      };

    case STAT_TYPES.GET_CONVERTED_USER_BY_OS_FAILURE:
      return {
        ...state,
        convertedUserByOS: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
      };
    default:
      return state;
  }
}
