import {
  loginBegin,
  loginFailure,
  loginSuccess,
  loginNo2FASuccess,
  resendLoginOTPBegin,
  resendLoginOTPSuccess,
  resendLoginOTPFailure,
  verifyLoginOTPBegin,
  verifyLoginOTPSuccess,
  verifyLoginOTPFailure,
  verifySessionBegin,
  verifySessionSuccess,
  verifySessionFailure,
  setSubmitting,
  getProfileDetailsBegin,
  getProfileDetailsSuccess,
  getProfileDetailsFailure,
  verifySessionOtpBegin,
  verifySessionOtpSuccess,
  verifySessionOtpFailure,
} from '../actions/authActions';
import { showSnack, resetSnack } from '../actions/alertActions';

import ENDPOINTS, { GET, POST } from '../../services/requests';

export function performLogin({ email, password }) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(loginBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.LOGIN,
        data: {
          email,
          password,
        },
      });

      if (!result.data.user) {
        dispatch(loginSuccess());

        dispatch(
          showSnack({
            message: 'Passcode sent successfully!',
            options: {
              variant: 'success',
            },
          })
        );
      } else {
        dispatch(
          loginNo2FASuccess({
            token: result.data.tokens,
            user: result.data.user,
            role: result.data.user.role,
          })
        );

        dispatch(
          showSnack({
            message: 'Logged in successfully!',
            options: {
              variant: 'success',
            },
          })
        );
      }

      return result;
    } catch (error) {
      dispatch(loginFailure());
      return false;
    }
  };
}

export function performVerifyAdminSession({ password }) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(verifySessionBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.VERIFY_ADMIN_SESSION,
        data: {
          password,
        },
      });

      dispatch(verifySessionSuccess());

      dispatch(
        showSnack({
          message: result.data.message,
          options: {
            variant: 'success',
          },
        })
      );

      return true;
    } catch (error) {
      dispatch(verifySessionFailure());
      return false;
    }
  };
}

export function performVerifyAdminSessionOtp({ passcode }) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(verifySessionOtpBegin());
    try {
      await POST({
        url: ENDPOINTS.VERIFY_ADMIN_SESSION_OTP,
        data: {
          otp: passcode,
        },
      });

      dispatch(verifySessionOtpSuccess());

      dispatch(
        showSnack({
          message: 'Admin session verified successfully!',
          options: {
            variant: 'success',
          },
        })
      );

      return true;
    } catch (error) {
      dispatch(verifySessionOtpFailure());
      return false;
    }
  };
}

export function performResendLoginOTP({ email }) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(resendLoginOTPBegin());
    try {
      await POST({
        url: ENDPOINTS.RESEND_LOGIN_OTP,
        data: {
          email,
        },
      });

      dispatch(resendLoginOTPSuccess());

      dispatch(
        showSnack({
          message: 'Passcode re-sent successfully!',
          options: {
            variant: 'success',
          },
        })
      );

      return true;
    } catch (error) {
      dispatch(resendLoginOTPFailure());
      return false;
    }
  };
}

export function performVerifyLoginOTP({ otp, email }) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(verifyLoginOTPBegin());
    try {
      const result = await POST({
        url: ENDPOINTS.VERIFY_LOGIN_OTP,
        data: {
          otp,
          email,
        },
      });

      dispatch(
        verifyLoginOTPSuccess({
          token: result.data.tokens,
          user: result.data.user,
          role: result.data.user.role,
        })
      );

      dispatch(
        showSnack({
          message: 'Passcode verified, Logged in successfully!',
          options: {
            variant: 'success',
          },
        })
      );

      return true;
    } catch (error) {
      dispatch(verifyLoginOTPFailure());
      return false;
    }
  };
}

export function performForgotPassword(data) {
  return async (dispatch) => {
    dispatch(setSubmitting(true));
    try {
      const result = await POST({ url: ENDPOINTS.FORGOT_PASSWORD, data });
      return result.data;
    } catch (error) {
      return false;
    } finally {
      dispatch(setSubmitting(false));
    }
  };
}

export function performResetPassword(resetPasswordToken, data) {
  return async (dispatch) => {
    dispatch(setSubmitting(true));
    try {
      const result = await POST({
        url: ENDPOINTS.RESET_PASSWORD,
        params: { resetPasswordToken },
        data,
      });
      dispatch(
        showSnack({
          message: result.data,
          options: {
            variant: 'success',
          },
        })
      );
      return true;
    } catch (error) {
      return false;
    } finally {
      dispatch(setSubmitting(false));
    }
  };
}

export function performGetProfileDetails(data) {
  return async (dispatch) => {
    dispatch(resetSnack());
    dispatch(getProfileDetailsBegin());
    try {
      const result = await GET({ url: ENDPOINTS.GET_PROFILE });

      dispatch(getProfileDetailsSuccess(result.data));

      return true;
    } catch (error) {
      dispatch(getProfileDetailsFailure());
      return false;
    }
  };
}
